.reviews {
    padding: 100px 0;
    position: relative;
}
.container {
}
.reviews__title {
}
.title {
}
.reviews__subtitle {
    font-weight: 400;
    font-size: 26px;
    line-height: 33px;
    color: #ffffff;
}
.reviews__wrapper {
    margin-top: 120px;
    width: calc(100% + 40px);
    transform: translateX(-20px);
    .swiper {
        padding: 18px 18px 0;
    }
    .swiper-pagination {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.reviews-item {
    border-radius: 10px;
    background-color: #000;
    $border: 3px;
    background-clip: padding-box;
    border: solid $border transparent;
    border-right: 0;
    border-left: 0;
    padding: 24px 20px 10px;
    min-height: 316px;
    display: flex;
    flex-direction: column;
    position: relative;
    &:hover {
        > p {
            &::before {
                opacity: 0;
            }
            &::after {
                opacity: 1;
            }
        }
    }
    &::before {
        content: "";
        position: absolute;
        background-image: url("../img/reviews-item-bfr.svg");
        background-repeat: no-repeat;
        background-size: cover;
        width: 243px;
        height: 295px;
        z-index: -1;
        left: -17px;
        bottom: -20px;
    }
    &::after {
        content: "";
        position: absolute;
        background-image: url("../img/reviews-item-aft.svg");
        background-repeat: no-repeat;
        background-size: cover;
        width: 243px;
        height: 295px;
        z-index: -1;
        right: -17px;
        top: -20px;
    }
    > span {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        margin: -$border;
        border-radius: inherit;
        background: linear-gradient(92.94deg, #ffea2b 12.91%, #51ffcb 71.79%);
        pointer-events: none;
    }
    > p {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        transition: all 0.3s ease-in-out;
        border-radius: 10px;
        overflow: hidden;
        pointer-events: none;
        &::before,
        &::after {
            width: 100%;
            height: 100%;
            position: absolute;
            content: "";
            transition: all 0.3s ease-in-out;
        }
        &::before {
            background: linear-gradient(
                108.65deg,
                #000000 1.1%,
                #2d2d2d 97.06%
            );
        }
        &::after {
            background: linear-gradient(
                107.46deg,
                rgba(81, 255, 203, 0) -12.51%,
                rgba(45, 45, 45, 0.66) 97.01%
            );
            opacity: 0;
        }
    }
}
.reviews-item__top {
    display: flex;
    z-index: 1;
    position: relative;
    img {
        width: 49px;
        min-width: 49px;
        height: 50px;
    }
    > div {
        margin-left: 22px;
    }
    p {
        font-weight: 300;
        font-size: 12px;
        line-height: 15px;
        color: #ffffff;
    }
}
.reviews-item__name {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #ffffff;
    font-family: "Krona One";
    z-index: 1;
    position: relative;
}
.reviews-item__descr {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #ffffff;
    margin-top: 10px;
    margin-bottom: 20px;
    z-index: 1;
    position: relative;
}
.reviews-item__bottom {
    margin-top: auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    z-index: 1;
    position: relative;
}
.reviews-item__date {
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    color: #ffffff;
    margin-right: 10px;
}
.reviews-item__arrow {
    background-image: url("../img/reviews-arrow.svg");
    width: 50px;
    height: 16px;
    display: block;
}

.reviews__bg {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: -1;
    object-fit: cover;
}

.nav-slider {
    display: flex;
    align-items: center;
    margin: 100px auto 0 auto;
    width: fit-content;

    .swiper-button-next,
    .swiper-button-prev {
        position: static;
        margin: 0;
        width: fit-content;
        &:hover {
            &::after {
                filter: brightness(20);
            }
        }
        &::after {
            font-size: 0;
            transition: all 0.3s ease-in-out;
        }
    }

    .swiper-button-next {
        &::after {
            content: url("../img/arrow-next.svg");
        }
    }
    .swiper-button-prev {
        &::after {
            content: url("../img/arrow-prev.svg");
        }
    }

    .swiper-pagination {
        position: static;
        margin: 0 20px;
    }

    .swiper-pagination-bullet {
        background: #5e5e5e;
        width: 15px;
        height: 15px;
        opacity: 1;
        margin: 0 14px !important;

        transform: scale(1);
    }
    .swiper-pagination-bullet-active {
        background: linear-gradient(141.2deg, #ffea2b 4.84%, #51ffcb 106.03%);
        transform: scale(1.4);
        transition: all 0.9s ease-in-out;
    }
}

@media (max-width: 1200px) {
    .reviews__wrapper .swiper {
        padding: 18px 40px 0;
    }
}

@media (max-width: 992px) {
    .reviews__wrapper {
        margin-top: 60px;
    }
}

@media (max-width: 767px) {
    .reviews {
        padding: 35px 0 50px;
    }

    .reviews__subtitle {
        font-size: 14px;
        line-height: 18px;
    }

    .nav-slider {
        margin-top: 55px;
    }

    .nav-slider .swiper-button-next,
    .nav-slider .swiper-button-prev {
        display: none;
    }
    .reviews__wrapper .swiper-pagination {
        padding-bottom: 4px;
    }

    .reviews__bg {
        display: none;
    }
}
