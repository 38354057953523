.modal-wrap {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.69);
    box-shadow: 10px 20px 50px rgba(0, 0, 0, 0.24);
    z-index: 20000000;
    opacity: 0;
    visibility: hidden;
    overflow-y: auto;
    transition: opacity 0.3s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;

    &.active {
        opacity: 1;
        visibility: visible;
    }
}

.modal {
    @include scroll-hide;
    overflow-y: auto;
    height: fit-content;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-height: 95vh;
    min-width: 532px;
    max-width: 532px;
}

.modal__body {
    background: linear-gradient(339.17deg, #48ff2b -5.77%, #51ffcb 104.59%);
    border-radius: 30px;
    padding: 80px 60px;
    width: 100%;
    position: relative;
    .form__title {
        color: #000000;
        font-size: 32px;
        line-height: 40px;
    }
    .form {
        input {
            color: rgba(0, 0, 0, 0.8);
            &::placeholder {
                color: rgba(0, 0, 0, 0.8);
            }
            border-bottom: 3px solid #000000;
        }
        h3 {
            color: #000000;
        }
        select {
            color: rgba(0, 0, 0, 0.8);
            &::placeholder {
                color: rgba(0, 0, 0, 0.8);
            }
            border-bottom: 3px solid #000000;
        }
    }
    .btn--telegram,
    .btn--whatsapp {
        background: linear-gradient(92.5deg, #000000 -4.72%, #000000 117.19%);
    }
}

.modal__close {
    position: absolute;
    right: 20px;
    top: 20px;
    width: 36px;
    height: 36px;
    border-radius: 8px;
    cursor: pointer;
    transition: filter 0.2s ease-in-out;

    @media (any-hover: hover) {
        &:hover {
        }
    }

    &::before {
        content: "";
        position: absolute;
        background-image: url("../img/close.svg");
        background-repeat: no-repeat;
        background-size: cover;
        width: 34px;
        height: 34px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        filter: brightness(0);
    }
}

@media (max-width: 550px) {
    .modal {
        min-width: 90%;
        max-width: 90%;
    }
    .modal__body {
        padding: 40px 20px;
        padding-top: 60px;
    }

    .modal__body .btn--telegram,
    .modal__body .btn--whatsapp {
        font-size: 14px;
    }

    .modal__body .form__title {
        font-size: 28px;
    }

    .form__wrap h3 {
        margin-top: 15px;
    }

    .form__wrap {
        margin-bottom: 30px;
    }
}
