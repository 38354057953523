.btn {
    font-family: "Krona One";
    border-radius: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    padding: 24px 33px;
    min-width: 299px;
    overflow: hidden;
    position: relative;
    transition: all 0.3s ease-in-out;
    &:after {
        background: rgb(255, 255, 255);
        content: "";
        height: 192px;
        left: -75px;
        opacity: 0.7;
        position: absolute;
        top: -50px;
        transform: rotate(35deg);
        transition: all 0.8s cubic-bezier(0.19, 1, 0.22, 1);
        width: 50px;
        z-index: 22;
    }
    span {
        font-family: "Krona One";
        z-index: 26;
    }
    &:hover {
        filter: hue-rotate(45deg);
        &:after {
            left: 120%;
            transition: all 0.8s cubic-bezier(0.19, 1, 0.22, 1);
        }
    }
}

.btn--orange {
    color: #000000;
    background: linear-gradient(95.2deg, #ffea2b 0.21%, #ffb951 111.21%);
}

.btn--orange-border {
    display: flex;
    align-items: center;
    position: relative;
    $border: 4px;
    background: #000;
    background-clip: padding-box;
    border: solid $border transparent;
    background: linear-gradient(96.34deg, #ffea2b 5.23%, #ffb951 102.8%);
    &:before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        margin: -$border;
        border-radius: inherit;
        background-color: #000;
        z-index: 21;
    }
    span {
        background: linear-gradient(91.96deg, #ffea2b 20.33%, #ffb951 120.72%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
    }
}

.btn--telegram {
    font-weight: 400;
    font-size: 20px;
    line-height: 25px;
    color: #ffffff;
    background: linear-gradient(92.5deg, #6bb8ff -4.72%, #2ad2ff 117.19%);
    margin-top: 15px;
    span {
        display: flex;
        align-items: center;
        &::after {
            content: "";
            background-image: url("../img/telegram-btn.png");
            background-repeat: no-repeat;
            background-size: cover;
            width: 41px;
            height: 41px;
            display: block;
            margin-left: 15px;
        }
    }
}

.btn--whatsapp {
    font-weight: 400;
    font-size: 20px;
    line-height: 25px;
    color: #ffffff;
    background: linear-gradient(92.5deg, #48ff2b -4.72%, #48ff2b 117.19%);
    margin-top: 15px;
    span {
        display: flex;
        align-items: center;
        &::after {
            content: "";
            background-image: url("../img/whatsapp-btn.png");
            background-repeat: no-repeat;
            background-size: cover;
            width: 24px;
            height: 24px;
            display: block;
            margin-left: 15px;
        }
    }
}

@media (max-width: 480px) {
    .btn {
        font-size: 14px;
        line-height: 18px;
        padding: 17px 33px;
    }
}
