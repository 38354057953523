:root {
  --color-black: #000;
  --color-white: #fff;
}

/* @include font-face("GothamPro", "GothamPro", 400, normal); */
*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:focus,
:active {
  outline: none;
}

a:focus,
a:active {
  outline: none;
}

a {
  text-decoration: none;
}

body {
  box-sizing: border-box;
}

aside,
nav,
footer,
header,
section {
  display: block;
}

input::-ms-clear {
  display: none;
}

button {
  cursor: pointer;
  border: none;
  background-color: transparent;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

ul li {
  list-style: none;
}

dl,
ol,
ul {
  margin: 0;
  padding: 0;
}

label {
  margin: 0;
}

p {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: inherit;
  font-size: inherit;
  margin: 0;
}

input:not([type=checkbox]):not([type=radio]),
textarea,
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

* {
  font-family: "Space Grotesk", sans-serif;
}

body {
  background-color: #000;
}
body.scroll-hide {
  overflow: hidden;
}

.container {
  max-width: 1177px;
  margin: 0 auto;
  padding: 0 15px;
}

.big-container {
  max-width: 1600px;
}

.page {
  position: relative;
  overflow: hidden;
  min-height: 100vh;
}
@media (max-width: 767px) {
  .page {
    position: relative;
  }
  .page::before {
    content: "";
    position: absolute;
    background-image: url("../img/page-line-mob.svg");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    left: 0;
    top: 300px;
  }
}

.orange-text {
  background: radial-gradient(58.93% 151.19% at 67.9% 50%, #ffea2b 0%, #ff9900 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.green-text {
  background: radial-gradient(31.01% 282.69% at 9.96% 22.9%, #ffea2b 0%, #51ffcb 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.title {
  font-weight: 400;
  font-size: 52px;
  line-height: 65px;
  background: radial-gradient(61.41% 1041.08% at 17.44% 38.59%, #ffea2b 0%, #51ffcb 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-family: "Krona One";
}

@media (max-width: 767px) {
  .title {
    font-size: 30px;
    line-height: 38px;
  }
}
.header {
  padding: 64px 0;
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 22222;
}
.header .soc-wrap {
  display: none;
}

.header__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  z-index: 2222;
}

.nav__list {
  display: flex;
}

.nav__item {
  margin-right: 60px;
}
.nav__item:last-child {
  margin-right: 0;
}

.nav__link {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
  position: relative;
}
.nav__link::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 2px;
  bottom: -2px;
  left: 0;
  background: linear-gradient(267.47deg, #ffea2b -4.85%, #48ff2b 56.9%, #96ffd9 106.85%);
  display: none;
}
.nav__link:hover {
  background: linear-gradient(267.47deg, #ffea2b -4.85%, #48ff2b 56.9%, #96ffd9 106.85%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.nav__link:hover::before {
  display: block;
}

@media (max-width: 1460px) {
  .header {
    padding: 30px 0;
  }
}
@media (max-width: 1200px) {
  .nav__item {
    margin-right: 35px;
  }

  .header {
    padding: 50px 0;
  }
}
@media (max-width: 992px) {
  .nav {
    position: fixed;
    width: 100%;
    height: 100%;
    background: #000000;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    padding: 150px 30px 30px;
    transform: scale(0.8);
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s ease-in-out;
    z-index: 222;
  }
  .nav.active {
    transform: scale(1);
    opacity: 1;
    pointer-events: all;
  }

  .nav__list {
    flex-direction: column;
  }
  .nav__list li {
    margin-right: 0;
    margin-bottom: 20px;
  }
  .nav__list li a {
    font-size: 16px;
    line-height: 20px;
  }

  .header .soc-wrap {
    display: flex;
    margin-top: auto;
    justify-content: center;
  }

  .burger {
    display: block !important;
  }

  .lang {
    display: none;
  }
}
.promo {
  padding-top: 258px;
  position: relative;
  padding-bottom: 170px;
}

.promo__bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: -3;
  pointer-events: none;
}

.promo__line {
  position: absolute;
  left: 0;
  bottom: 25px;
  width: 100%;
  z-index: -2;
  pointer-events: none;
}

.promo__wrapper {
  position: relative;
}

.promo-ofer {
  max-width: 680px;
}

.promo__title {
  font-weight: 400;
  font-size: 46px;
  line-height: 58px;
  font-family: "Krona One";
  background: radial-gradient(83.55% 1146.25% at 24.13% 44.74%, #ffea2b 0%, rgba(81, 255, 203, 0.94) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  text-shadow: 0px 0px 5px rgba(81, 255, 203, 0.94);
}

.promo__subtitle {
  font-weight: 400;
  font-size: 28px;
  line-height: 35px;
  color: #ffffff;
  font-family: "Krona One";
}
.promo__subtitle span {
  font-family: "Krona One";
}

.promo__descr {
  margin-top: 25px;
  max-width: 610px;
}
.promo__descr div {
  font-weight: 700;
  font-size: 26px;
  line-height: 33px;
  color: #ffffff;
  position: relative;
}
.promo__descr div::before {
  content: "";
  position: absolute;
  background-image: url("../img/line-promo-descr.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 179px;
  height: 7px;
  bottom: -5px;
}
.promo__descr p {
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  color: #ffffff;
  margin-top: 10px;
}

.promo-advant {
  margin-top: 35px;
  display: flex;
}

.promo-advant-item--first {
  border-radius: 41px;
  padding: 30px 25px;
  font-weight: 700;
  font-size: 34px;
  line-height: 43px;
  color: #000000;
  background: radial-gradient(67.7% 124.15% at 21.48% 10.11%, rgba(81, 255, 203, 0.91) 0%, rgba(72, 255, 43, 0.91) 100%);
}
.promo-advant-item--first p {
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #000000;
}

.promo-advant-item {
  margin-left: 50px;
  width: fit-content;
  position: relative;
  border-radius: 41px;
  padding: 30px 25px;
  background: #000;
  background-clip: padding-box;
  border: solid 3px transparent;
  position: relative;
}
.promo-advant-item:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  margin: -3px;
  border-radius: inherit;
  background: linear-gradient(121.42deg, #48ff2b -0.29%, rgba(255, 255, 255, 0) 63.34%);
  pointer-events: none;
}
.promo-advant-item div {
  font-weight: 700;
  font-size: 30px;
  line-height: 38px;
  background: radial-gradient(130% 720.72% at 24.03% 6%, #ffea2b 0%, #51ffcb 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  text-shadow: 0px 0px 4px #96ffd9;
}
.promo-advant-item p {
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #ffffff;
  margin-top: 5px;
}
.promo-advant-item::before {
  position: absolute;
  border-radius: 41px;
  content: "";
  width: 100%;
  height: 100%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  text-shadow: 0px 0px 4px #96ffd9;
  background: radial-gradient(97.75% 179.73% at 26.01% 24.32%, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0) 100%);
  left: 0;
  top: 0;
  pointer-events: none;
}

.promo-btn-wrap {
  margin-top: 48px;
  display: grid;
  grid-template-columns: repeat(2, 2fr);
  gap: 35px;
}

.promo-img {
  position: absolute;
  top: -190px;
  right: -73px;
  z-index: -1;
  pointer-events: none;
}

@media (max-width: 1460px) {
  .promo {
    padding-top: 155px;
    padding-bottom: 85px;
  }

  .promo__title {
    font-size: 32px;
    line-height: 39px;
    max-width: 500px;
  }

  .promo__subtitle {
    font-size: 20px;
    line-height: 28px;
    max-width: 500px;
  }

  .promo-ofer {
    max-width: 661px;
  }

  .promo__descr p {
    font-size: 14px;
    line-height: 19px;
  }

  .promo__descr div {
    font-size: 18px;
    line-height: 25px;
  }

  .promo-advant-item {
    padding: 20px 25px;
  }

  .promo-advant-item--first {
    padding: 20px 25px;
    font-size: 26px;
    line-height: 34px;
  }

  .promo-advant-item--first p {
    font-size: 16px;
    line-height: 21px;
  }

  .promo-advant-item p {
    font-size: 16px;
    line-height: 21px;
  }

  .promo-advant-item div {
    font-size: 26px;
    line-height: 34px;
  }

  .promo-advant {
    margin-top: 25px;
  }

  .promo-btn-wrap {
    margin-top: 35px;
  }

  .promo-advant-item {
    margin-left: 25px;
  }

  .promo-img {
    width: 727px;
    top: -145px;
  }
  .promo-img img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
  }
}
@media (max-width: 1200px) {
  .promo-img {
    right: -472px;
    top: -253px;
    z-index: -1;
  }

  .promo__bg {
    display: none;
  }
}
@media (max-width: 992px) {
  .promo__title {
    font-size: 25px;
    line-height: 31px;
  }

  .promo__subtitle {
    font-size: 16px;
    line-height: 20px;
    margin-top: 10px;
  }

  .promo__descr div {
    font-size: 14px;
    line-height: 18px;
  }
  .promo__descr div::before {
    background-position: right;
    width: 94px;
  }

  .promo__descr {
    margin-top: 16px;
  }

  .promo__descr p {
    font-size: 12px;
    line-height: 15px;
  }

  .promo-advant-item--first {
    padding: 15px 10px;
  }

  .promo-advant-item--first p {
    font-size: 14px;
    line-height: 18px;
  }

  .promo-advant-item--first {
    font-size: 26px;
    line-height: 33px;
  }

  .promo-advant-item {
    padding: 15px 10px;
  }

  .promo-advant-item p {
    font-size: 16px;
    line-height: 20px;
  }

  .promo-advant-item div {
    font-size: 26px;
    line-height: 33px;
  }
}
@media (max-width: 767px) {
  .promo-btn-wrap {
    grid-template-columns: 1fr;
    gap: 20px;
    margin-top: 35px;
  }

  .promo__btn {
    width: 304px;
    margin: 0 auto;
  }

  .promo-advant-item {
    margin-left: 11px;
  }

  .promo__line {
    left: -492px;
    bottom: 179px;
    transform: rotate(351deg);
    width: 211%;
  }
}
@media (max-width: 420px) {
  .promo__title {
    font-size: 21px;
    line-height: 29px;
  }

  .header {
    padding: 20px 0;
  }

  .promo__subtitle {
    font-size: 14px;
    line-height: 18px;
  }

  .promo-advant-item--first p {
    font-size: 11px;
    line-height: 14px;
  }

  .promo-advant-item--first {
    font-size: 20px;
    line-height: 28px;
    border-radius: 28px;
  }

  .promo-advant-item p {
    font-size: 11px;
    line-height: 15px;
  }

  .promo-advant-item div {
    font-size: 23px;
    line-height: 28px;
  }

  .promo-advant-item {
    border-radius: 28px;
  }

  .promo {
    padding-top: 130px;
  }
  .promo .promo__btn {
    padding: 15px 33px;
    font-size: 14px;
    line-height: 18px;
  }

  .promo-btn-wrap {
    margin-top: 25px;
    gap: 15px;
  }

  .logo {
    width: 150px;
  }
  .logo img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
  }

  .promo-img {
    right: -342px;
    top: -156px;
  }
}
.btn {
  font-family: "Krona One";
  border-radius: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  padding: 24px 33px;
  min-width: 299px;
  overflow: hidden;
  position: relative;
  transition: all 0.3s ease-in-out;
}
.btn:after {
  background: white;
  content: "";
  height: 192px;
  left: -75px;
  opacity: 0.7;
  position: absolute;
  top: -50px;
  transform: rotate(35deg);
  transition: all 0.8s cubic-bezier(0.19, 1, 0.22, 1);
  width: 50px;
  z-index: 22;
}
.btn span {
  font-family: "Krona One";
  z-index: 26;
}
.btn:hover {
  filter: hue-rotate(45deg);
}
.btn:hover:after {
  left: 120%;
  transition: all 0.8s cubic-bezier(0.19, 1, 0.22, 1);
}

.btn--orange {
  color: #000000;
  background: linear-gradient(95.2deg, #ffea2b 0.21%, #ffb951 111.21%);
}

.btn--orange-border {
  display: flex;
  align-items: center;
  position: relative;
  background: #000;
  background-clip: padding-box;
  border: solid 4px transparent;
  background: linear-gradient(96.34deg, #ffea2b 5.23%, #ffb951 102.8%);
}
.btn--orange-border:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  margin: -4px;
  border-radius: inherit;
  background-color: #000;
  z-index: 21;
}
.btn--orange-border span {
  background: linear-gradient(91.96deg, #ffea2b 20.33%, #ffb951 120.72%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.btn--telegram {
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  color: #ffffff;
  background: linear-gradient(92.5deg, #6bb8ff -4.72%, #2ad2ff 117.19%);
  margin-top: 15px;
}
.btn--telegram span {
  display: flex;
  align-items: center;
}
.btn--telegram span::after {
  content: "";
  background-image: url("../img/telegram-btn.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 41px;
  height: 41px;
  display: block;
  margin-left: 15px;
}

.btn--whatsapp {
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  color: #ffffff;
  background: linear-gradient(92.5deg, #48ff2b -4.72%, #48ff2b 117.19%);
  margin-top: 15px;
}
.btn--whatsapp span {
  display: flex;
  align-items: center;
}
.btn--whatsapp span::after {
  content: "";
  background-image: url("../img/whatsapp-btn.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 24px;
  height: 24px;
  display: block;
  margin-left: 15px;
}

@media (max-width: 480px) {
  .btn {
    font-size: 14px;
    line-height: 18px;
    padding: 17px 33px;
  }
}
.awards {
  background-color: #000;
  padding: 50px 0;
  background: #000;
  background-clip: padding-box;
  border: solid 4px transparent;
  position: relative;
  border-right: 0;
  border-left: 0;
}
@media (min-width: 767px) {
  .awards {
    min-height: 296px;
  }
  .awards .container {
    margin-right: 70px;
    padding: 0;
    max-width: 100%;
  }
  .awards .container:first-child {
    margin-right: 0;
  }
  .awards .awards-item {
    margin-right: 70px;
  }
}
.awards:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  margin: -4px;
  border-radius: inherit;
  background: linear-gradient(92.94deg, #ffea2b 12.91%, #51ffcb 71.79%);
  pointer-events: none;
}
.awards .swiper-wrapper {
  align-items: center;
  -webkit-transition-timing-function: linear !important;
  transition-timing-function: linear !important;
  justify-content: space-between;
}
.awards .swiper-slide {
  width: auto;
}
.awards .awards-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.awards .awards-item div {
  height: 110px;
  display: flex;
  align-items: center;
}
.awards .awards-item p {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  width: 100px;
  margin-top: 25px;
}
.awards .swiper-button-next,
.awards .swiper-button-prev {
  display: none;
}
.awards .swiper-pagination {
  display: none;
}

.awards__title {
  text-align: center;
  background: linear-gradient(95.53deg, #ffea2b 3.89%, #51ffcb 87.23%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-weight: 400;
  font-size: 32px;
  line-height: 40px;
  font-family: "Krona One";
}

.awards__wrapper {
  margin-top: 35px;
}

@media (max-width: 767px) {
  .awards .swiper-grid-column > .swiper-wrapper {
    flex-direction: unset;
    row-gap: 30px;
  }
  .awards .awards-item p {
    display: none;
  }
  .awards .swiper {
    padding-bottom: 80px;
  }
  .awards .swiper-pagination-bullet {
    background: #5e5e5e;
    width: 12px;
    height: 12px;
    opacity: 1;
    margin: 0 3px !important;
    transform: scale(1);
  }
  .awards .swiper-pagination-bullet-active {
    background: linear-gradient(141.2deg, #ffea2b 4.84%, #51ffcb 106.03%);
    transition: all 0.9s ease-in-out;
  }

  .awards .awards-item {
    margin-top: 0 !important;
  }

  .awards .swiper-pagination {
    display: flex;
    justify-content: center;
  }

  .awards__title {
    font-size: 25px;
    line-height: 31px;
  }

  .awards {
    padding: 17px 0 35px 0;
  }
}
.about {
  padding: 100px 0 60px;
}
.about .container {
  position: relative;
}

.about__wrapper {
  max-width: 927px;
  margin-left: auto;
}

.about__img {
  position: absolute;
  top: -181px;
  left: -239px;
  z-index: -1;
}

.about__title {
  font-weight: 400;
  font-size: 32px;
  line-height: 40px;
  color: #ffffff;
  font-family: "Krona One";
}
.about__title span {
  font-family: "Krona One";
}

.about__subtitle {
  font-weight: 700;
  font-size: 26px;
  line-height: 33px;
  color: #ffffff;
  margin-top: 20px;
  max-width: 700px;
}

.about__list {
  margin-top: 20px;
  padding-left: 20px;
  position: relative;
}
.about__list::before {
  content: "";
  position: absolute;
  background-image: url("../img/about-line.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 12px;
  height: 100%;
  left: -3px;
}
.about__list li {
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  color: #ffffff;
  margin-bottom: 15px;
}
.about__list li:last-child {
  margin-bottom: 0;
}

.about__descr {
  font-weight: 300;
  font-size: 18px;
  line-height: 23px;
  color: #ffffff;
  margin-top: 30px;
  max-width: 780px;
}

.about__btn {
  margin-top: 47px;
  width: 350px;
}

@media (max-width: 767px) {
  .about__title {
    font-size: 25px;
    line-height: 31px;
  }

  .about__subtitle {
    font-size: 18px;
    line-height: 24px;
  }

  .about__list li {
    font-size: 12px;
    line-height: 15px;
  }

  .about__descr {
    font-size: 14px;
    line-height: 18px;
    margin-top: 15px;
  }

  .about__btn {
    margin: 45px auto 0;
  }

  .about__img {
    display: none;
  }

  .about {
    padding: 60px 0 30px 0;
  }

  .about__list::before {
    background-position: bottom;
  }
}
.cases {
  padding: 133px 0;
}
.cases .container {
  max-width: 1240px;
}

.cases__subtitle {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  max-width: 401px;
}

.cases__wrapper {
  margin-top: 40px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 52px;
  column-gap: 30px;
}

.cases-item {
  display: flex;
  height: fit-content;
}
.cases-item > div {
  display: flex;
  flex-direction: column;
}
.cases-item:hover .cases-item__img::before {
  opacity: 1;
}
.cases-item:hover .cases-item__btn {
  background: radial-gradient(125% 4666.67% at 95.24% 125%, #ffea2b 0%, #51ffcb 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
.cases-item:hover .cases-item__btn::after {
  background-image: url("../img/btn-arrow-gradi.svg");
}

.cases-item__img {
  width: 285px;
  min-width: 285px;
  height: 282px;
  margin-right: 34px;
  position: relative;
}
.cases-item__img::before {
  content: "";
  position: absolute;
  background: linear-gradient(114.81deg, rgba(255, 234, 43, 0.77) 8.17%, rgba(81, 255, 203, 0.77) 105.29%);
  border-radius: 10px;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}
.cases-item__img img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

.cases-item__logo {
  height: fit-content;
}

.cases-item__title {
  font-family: "Krona One";
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  background: radial-gradient(134.21% 2801.18% at 8.42% -34.21%, #ffea2b 0%, #51ffcb 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  margin-top: 16px;
}

.cases-item__descr {
  margin-top: 15px;
  margin-bottom: 20px;
}
.cases-item__descr h3 {
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  font-family: "Krona One";
  padding-bottom: 2px;
}
.cases-item__descr p {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  margin-bottom: 6px;
}
.cases-item__descr p span {
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  font-family: "Krona One";
  padding-bottom: 2px;
}

.cases-item__btn {
  font-family: "Krona One";
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  margin-top: auto;
  display: flex;
  align-items: center;
  transition: all 0.3s ease-in-out;
}
.cases-item__btn::after {
  content: "";
  background-image: url("../img/btn-arrow.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 57px;
  height: 15px;
  margin-left: 11px;
}

.cases__bottom {
  margin-top: 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.cases__bottom p {
  font-weight: 400;
  font-size: 25px;
  line-height: 32px;
  text-align: center;
  color: #ffffff;
  max-width: 565px;
}

.cases__btn {
  margin-top: 12px;
  width: 350px;
}

@media (max-width: 1460px) {
  .cases-item__img {
    width: 200px;
    min-width: 200px;
    height: 200px;
  }

  .cases-item__logo {
    height: 40px;
  }
  .cases-item__logo img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
  }

  .cases-item__title {
    font-size: 12px;
    line-height: 15px;
    margin-top: 8px;
  }

  .cases-item__descr {
    margin: 10px 0;
  }

  .cases-item__descr h3 {
    font-size: 12px;
    line-height: 16px;
  }

  .cases-item__descr p {
    font-size: 12px;
    line-height: 16px;
  }

  .cases-item__descr p span {
    font-size: 12px;
    line-height: 14px;
  }

  .cases-item__btn {
    font-size: 14px;
    line-height: 14px;
  }

  .cases__wrapper {
    margin-top: 30px;
    row-gap: 30px;
  }

  .cases__bottom p {
    font-size: 18px;
    line-height: 24px;
  }

  .cases__btn {
    padding: 15px 33px;
  }
}
@media (max-width: 1200px) {
  .cases-item {
    flex-direction: column;
  }

  .cases-item__img {
    margin-right: 0;
    margin-bottom: 16px;
  }
}
@media (max-width: 767px) {
  .cases__wrapper {
    grid-template-columns: 1fr;
    width: 285px;
    margin: 40px auto 0 auto;
  }

  .cases__subtitle {
    font-size: 14px;
    line-height: 18px;
  }

  .cases__bottom p {
    font-size: 14px;
    line-height: 18px;
  }

  .cases {
    padding: 60px 0 90px;
  }

  .cases-item {
    position: relative;
    padding-top: 70px;
  }

  .cases-item__logo {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  .cases-item__title {
    margin-top: 0;
  }
}
.marketing {
  position: relative;
  padding: 100px 0;
  background-clip: padding-box;
  border: solid 4px transparent;
  border-right: 0;
  border-left: 0;
}
.marketing:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  margin: -4px;
  border-radius: inherit;
  background: linear-gradient(92.94deg, #ffea2b 12.91%, #51ffcb 71.79%);
  pointer-events: none;
}
.marketing .container {
  max-width: 1176px;
}

.marketing__bg {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: -1;
  object-fit: cover;
}

.marketing__title {
  font-family: "Krona One";
  font-weight: 400;
  font-size: 49px;
  line-height: 61px;
  background: linear-gradient(92.88deg, #ffea2b 9.78%, #51ffcb 83.35%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  z-index: 2;
  position: relative;
}

.marketing__descr {
  font-weight: 500;
  font-size: 36px;
  line-height: 46px;
  color: #ffffff;
  text-shadow: -3px 4px 3px rgba(0, 0, 0, 0.25);
  max-width: 861px;
  margin-top: 23px;
  z-index: 2;
  position: relative;
}
.marketing__descr .green {
  font-weight: 700;
  font-size: 40px;
  line-height: 51px;
  color: #48ff2b;
}

.marketing__bottom {
  margin-top: 60px;
  font-weight: 500;
  font-size: 21px;
  line-height: 27px;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
  position: relative;
  z-index: 2;
  position: relative;
}
.marketing__bottom::before {
  content: "";
  position: absolute;
  background-image: url("../img/marketing-arrow-btn.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 71px;
  height: 83px;
  right: -119px;
  top: 0;
}

.marketing__btn {
  padding: 23px 21px;
}

.marketing__tel {
  position: absolute;
  right: 119px;
  top: -90px;
}

.marketing__check {
  position: absolute;
}

.marketing__check1 {
  left: 19px;
  bottom: 96px;
}

.marketing__check2 {
  right: -25px;
  bottom: 7px;
}

.marketing__check3 {
  right: 53px;
  top: 46px;
}

@media (max-width: 1400px) {
  .marketing__check1 {
    bottom: inherit;
    top: 0;
    left: -89px;
    top: -116px;
  }

  .marketing__tel {
    right: -209px;
  }

  .marketing__check3 {
    right: 111px;
    top: 306px;
  }

  .marketing__check2 {
    right: -116px;
    bottom: -110px;
  }
}
@media (max-width: 992px) {
  .marketing__title {
    max-width: 397px;
  }

  .marketing__descr {
    max-width: 597px;
  }
}
@media (max-width: 767px) {
  .marketing__title {
    font-size: 30px;
    line-height: 38px;
    max-width: 282px;
  }

  .marketing__descr {
    font-size: 16px;
    line-height: 20px;
    max-width: 308px;
  }
  .marketing__descr .green {
    font-size: 16px;
    line-height: 20px;
  }

  .marketing__tel {
    height: 469px;
    right: -156px;
  }

  .marketing__check3 {
    right: 53px;
    top: 188px;
  }

  .marketing__check2 {
    height: 230px;
    right: -80px;
    bottom: -99px;
  }

  .marketing__check1 {
    height: 230px;
  }

  .marketing__bottom {
    margin: 60px auto 0 auto;
  }

  .marketing__btn {
    height: 64px;
  }

  .marketing__bottom::before {
    right: -23px;
    top: -52px;
  }

  .marketing {
    padding: 140px 0 100px 0;
    margin-top: 100px;
  }
}
.project {
  padding: 167px 0 100px;
  position: relative;
}
.project .container {
  max-width: 1177px;
}

.project__title {
  font-family: "Krona One";
  font-weight: 400;
  font-size: 42px;
  line-height: 52px;
  color: #ffffff;
}
.project__title span {
  font-family: "Krona One";
}

.project-tabs {
  margin-top: 55px;
  display: flex;
  flex-wrap: wrap;
  max-width: 640px;
  position: relative;
  z-index: 1;
}

.project-tabs-item {
  padding: 20px 30px;
  border-radius: 56px;
  background-clip: padding-box;
  border: solid 4px transparent;
  position: relative;
  background-color: #000;
  width: fit-content;
  min-width: 167px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: 15px;
  margin-bottom: 34px;
}
.project-tabs-item:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  margin: -4px;
  border-radius: inherit;
  background: linear-gradient(92.94deg, #ffea2b 12.91%, #51ffcb 71.79%);
  pointer-events: none;
}
.project-tabs-item span {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  background: radial-gradient(88.33% 6494.5% at 7.53% 0%, #ffea2b 0%, #51ffcb 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  pointer-events: none;
}
.project-tabs-item.active {
  background: linear-gradient(102.33deg, #48ff2b 9.01%, #51ffcb 96.42%);
}
.project-tabs-item.active span {
  color: #000000;
  background: none;
  -webkit-text-fill-color: inherit;
}

.project-content.hide {
  display: none;
}

.project-content__list {
  padding-left: 10px;
}
.project-content__list li {
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #ffffff;
  margin-bottom: 17px;
  padding-left: 42px;
  position: relative;
}
.project-content__list li::before {
  content: "";
  position: absolute;
  background-image: url("../img/check-mark.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 20px;
  height: 20px;
  left: 0;
  top: 1px;
}

.project-content__descr {
  font-weight: 300;
  font-size: 18px;
  line-height: 30px;
  color: #ffffff;
  margin-top: 37px;
  max-width: 487px;
}

.project-content__btn {
  margin-top: 41px;
  width: 474px;
}

.project-content__img {
  position: absolute;
  height: 100%;
  right: 0;
  object-fit: cover;
  top: 0;
  z-index: -1;
  pointer-events: none;
}

@media (max-width: 767px) {
  .project {
    padding: 80px 0 45px 0;
  }

  .project__title {
    font-size: 22px;
    line-height: 28px;
  }

  .project-tabs {
    margin-top: 20px;
  }

  .project-tabs-item {
    margin-bottom: 15px;
  }

  .project-content__list li {
    font-size: 12px;
    line-height: 15px;
    padding-left: 34px;
    margin-bottom: 13px;
  }

  .project-content__list li::before {
    top: 0px;
  }

  .project-content__list {
    margin-top: 15px;
  }

  .project-content__descr {
    font-size: 14px;
    line-height: 18px;
    margin-top: 15px;
  }

  .project-content__btn {
    margin: 30px auto 0 auto;
    max-width: 365px;
    width: 100%;
    padding: 24px 21px !important;
    font-size: 14px;
    line-height: 18px;
  }

  .project-content__img {
    transform: translateX(40%);
    opacity: 0.8;
  }

  .project-tabs-item {
    padding: 10px;
  }
  .project-tabs-item:first-child {
    grid-column: 1/3;
    width: 100%;
  }
  .project-tabs-item span {
    font-size: 12px;
    line-height: 13px;
  }

  .project-tabs {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}
.services {
  padding: 59px 0 85px;
  position: relative;
}
.services .nav-slider {
  display: none;
}

.services__bg {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: -1;
  object-fit: cover;
}

.services__title {
  font-size: 42px;
  line-height: 52px;
}

.services__descr {
  font-weight: 300;
  font-size: 26px;
  line-height: 33px;
  color: #ffffff;
  max-width: 808px;
  margin-top: 10px;
}

.services__wrapper {
  margin-top: 78px;
}

@media (min-width: 767px) {
  .services__wrapper .swiper-wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 35px;
    row-gap: 50px;
  }
  .services__wrapper .swiper {
    overflow: visible;
  }
}
@media (min-width: 767px) and (max-width: 1200px) {
  .services__wrapper .swiper-wrapper {
    grid-template-columns: repeat(3, 1fr);
  }
  .services__wrapper .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media (min-width: 767px) and (max-width: 920px) {
  .services__wrapper .swiper-wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}

.services-item {
  position: relative;
  width: 260px;
  height: 342px;
  padding: 15px 15px 25px 15px;
  display: flex;
  flex-direction: column;
}
.services-item::before {
  content: "";
  position: absolute;
  background-image: url("../img/services-border.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  pointer-events: none;
}
.services-item::after {
  position: absolute;
  content: "";
  background: radial-gradient(113.69% 212.07% at 12.12% 7.31%, rgba(255, 234, 43, 0.23) 0%, rgba(81, 255, 203, 0.23) 100%);
  border-radius: 30px;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  pointer-events: none;
}
.services-item:hover::after {
  opacity: 1;
}

.services-item__top {
  display: flex;
  align-items: center;
}
.services-item__top div {
  width: 58px;
  min-width: 58px;
  height: 58px;
  border-radius: 20px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.services-item__top div::before {
  content: "";
  position: absolute;
  background-image: url("../img/services-item-border.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  pointer-events: none;
  left: 0;
  top: 0;
}
.services-item__top p {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  margin-left: 8px;
  background: radial-gradient(148.08% 1844.93% at -8.45% -48.08%, #ffea2b 0%, #51ffcb 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.services-item__desrc {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #ffffff;
  margin-top: 23px;
  margin-bottom: 20px;
}

.services-item__bottom {
  margin-top: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.services-item__btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 130px;
  height: 32px;
  border-radius: 56px;
  font-weight: 400;
  font-size: 8px;
  line-height: 10px;
  overflow: hidden;
  position: relative;
  transition: all 0.3s ease-in-out;
}
.services-item__btn:after {
  background: white;
  content: "";
  height: 110px;
  left: -75px;
  opacity: 0.7;
  position: absolute;
  top: -50px;
  transform: rotate(35deg);
  transition: all 0.8s cubic-bezier(0.19, 1, 0.22, 1);
  width: 26px;
  z-index: 22;
}
.services-item__btn:hover {
  filter: hue-rotate(45deg);
}
.services-item__btn:hover:after {
  left: 120%;
  transition: all 0.8s cubic-bezier(0.19, 1, 0.22, 1);
}
.services-item__btn span {
  font-family: "Krona One";
  z-index: 22;
}

.services-item__btn--gradi {
  background: linear-gradient(93.25deg, #ffea2b -2.32%, #51ffcb 127.24%);
  color: #000000;
  margin-bottom: 14px;
}

.services-item__btn--border {
  background: #000;
  background-clip: padding-box;
  border: solid 1px transparent;
  background: linear-gradient(92.63deg, #ffea2b 11.65%, #48ff2b 84.85%);
}
.services-item__btn--border:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  margin: -1px;
  border-radius: inherit;
  background-color: #000;
  z-index: 21;
}
.services-item__btn--border span {
  background: linear-gradient(92.63deg, #ffea2b 11.65%, #48ff2b 84.85%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

@media (max-width: 767px) {
  .services .swiper-grid-column > .swiper-wrapper {
    flex-direction: unset;
    row-gap: 54px;
  }
  .services .swiper-slide {
    margin-top: 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .services .nav-slider {
    margin: 40px auto 0 auto;
    display: flex;
  }

  .services__title {
    font-size: 25px;
    line-height: 31px;
  }

  .services__descr {
    font-size: 14px;
    line-height: 18px;
    margin-top: 2px;
  }

  .services__wrapper {
    margin-top: 45px;
  }

  .services {
    padding: 45px 0 20px;
  }

  .services__bg {
    display: none;
  }
}
.choose {
  padding: 90px 0;
}
.choose .container {
  position: relative;
}

.choose__top {
  font-weight: 400;
  font-size: 21px;
  line-height: 27px;
  width: 560px;
  margin-left: auto;
  padding-left: 20px;
  position: relative;
  margin-top: 19px;
}
.choose__top::before {
  content: "";
  position: absolute;
  background-image: url("../img/arrow-choose.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 16px;
  height: 63px;
  left: -3px;
  top: 11px;
}
.choose__top span {
  padding-left: 5px;
}

.choose__title {
  font-size: 42px;
  line-height: 52px;
  margin-bottom: 5px;
}

.choose__wrapper {
  margin-top: 80px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 40px;
  row-gap: 130px;
}
.choose__wrapper .gallery a {
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  text-decoration-line: underline;
  color: #2ad2ff;
  transition: all 0.3s ease-in-out;
  display: none;
}
.choose__wrapper .gallery a:first-child {
  display: block;
}
.choose__wrapper .gallery a:hover {
  letter-spacing: 1px;
}
.choose__wrapper .gallery a img {
  display: none;
}

.choose__wrapper--text {
  margin-top: 130px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 40px;
  row-gap: 50px;
}

.choose-item {
  padding: 55px 35px;
  position: relative;
  height: fit-content;
}
.choose-item::before {
  pointer-events: none;
  background-repeat: no-repeat;
  background-size: cover;
  content: "";
  position: absolute;
}
.choose-item:nth-child(odd) {
  transform: translateY(-129px);
}

.choose-item__icon {
  position: absolute;
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  pointer-events: none;
}

.choose-item__title {
  font-weight: 700;
  font-size: 26px;
  line-height: 33px;
  background: linear-gradient(94.11deg, #ffea2b -1.1%, #51ffcb 19.66%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
.choose-item__title span {
  font-size: 32px;
  line-height: 41px;
}

.choose-item__descr {
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  color: #ffffff;
  margin-top: 16px;
}

.choose-item--orange::before {
  background-image: url("../img/choose-item--orange.svg");
  width: 100%;
  height: 246px;
  left: 0;
  top: 0;
}
.choose-item--orange .choose-item__icon {
  width: 113px;
  height: 113px;
  right: 55px;
  top: -62px;
}

.choose-item--green {
  background: radial-gradient(67.7% 124.15% at 21.48% 10.11%, rgba(81, 255, 203, 0.91) 0%, rgba(72, 255, 43, 0.91) 100%);
}
.choose-item--green .choose-item__icon {
  background-color: transparent;
  right: 10px;
  top: 10px;
}
.choose-item--green .choose-item__title {
  color: #000000;
  background: none;
  -webkit-text-fill-color: inherit;
}
.choose-item--green .choose-item__descr {
  color: #000000;
}

.choose-item--blue::before {
  background-image: url("../img/choose-item--blue.svg");
  width: 100%;
  height: 238px;
  left: 0;
  top: 0;
}
.choose-item--blue .choose-item__icon {
  right: 20px;
  top: 20px;
}

.choose-item--top .choose-item__icon {
  background-color: transparent;
  right: 15px;
  top: 20px;
}

.choose-item--team::before {
  background-image: url("../img/choose-item--team.svg");
  width: 100%;
  height: 246px;
  left: 0;
  top: 0;
}
.choose-item--team .choose-item__icon {
  border: 3px solid rgba(255, 234, 43, 0.4);
  width: 113px;
  height: 113px;
  left: 111px;
  top: -62px;
}

.choose-item-text {
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  color: #ffffff;
  height: fit-content;
}
.choose-item-text span {
  font-weight: 700;
}
.choose-item-text:nth-child(odd) {
  transform: translateY(-129px);
}

.gradi-text {
  background: linear-gradient(93.5deg, #ffea2b -0.2%, #51ffcb 40%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.choose__btn {
  width: 464px;
}

.choose__bg {
  position: absolute;
  bottom: 110px;
  left: 50%;
  transform: translateX(-50%);
  pointer-events: none;
}

@media (max-width: 1200px) {
  .choose-item:nth-child(odd) {
    transform: translateY(0) translateX(-40px);
  }
  .choose-item:nth-child(odd)::before {
    background-position: right;
  }

  .choose-item:nth-child(even) {
    transform: translateX(40px);
  }
  .choose-item:nth-child(even)::before {
    background-position: left;
  }

  .choose__top {
    width: 100%;
  }

  .choose__wrapper {
    row-gap: 60px;
    column-gap: 0;
  }

  .choose-item-text:nth-child(odd) {
    transform: translateY(0);
  }

  .choose__btn {
    margin-top: 65px;
  }
}
@media (max-width: 992px) {
  .choose__wrapper {
    grid-template-columns: 1fr;
    row-gap: 30px;
  }

  .choose-item {
    width: 557px;
    padding: 20px;
    height: 249px;
  }

  .choose-item:nth-child(odd) {
    transform: translateY(0) translateX(40px);
    margin-left: auto;
    padding-right: 60px;
  }

  .choose-item:nth-child(even) {
    transform: translateY(0) translateX(-40px);
    margin-right: auto;
    padding-left: 60px;
  }

  .choose-item--orange .choose-item__icon {
    width: 85px;
    height: 85px;
    top: -41px;
  }
  .choose-item--orange .choose-item__icon img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
  }

  .choose-item--team .choose-item__icon {
    width: 85px;
    height: 85px;
    top: -41px;
    padding: 15px;
  }
  .choose-item--team .choose-item__icon img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
  }

  .choose-item--blue::before {
    height: 240px;
  }

  .choose-item--team::before {
    height: 248px;
  }

  .choose-item--green .choose-item__icon {
    right: 74px;
    top: 19px;
    width: 40px;
    height: 40px;
  }
  .choose-item--green .choose-item__icon img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
  }

  .choose-item--team {
    padding-top: 65px;
    padding-left: 51px;
  }

  .choose-item__title {
    font-size: 14px;
    line-height: 18px;
  }
  .choose-item__title span {
    font-size: 25px;
    line-height: 32px;
  }

  .choose-item--orange {
    margin-top: 30px;
  }

  .choose-item--blue {
    padding-top: 50px;
  }

  .choose-item--top {
    padding: 50px 30px;
  }
}
@media (max-width: 767px) {
  .choose-item:nth-child(odd) {
    transform: translateY(0) translateX(20px);
    padding-right: 90px;
  }

  .choose-item:nth-child(even) {
    transform: translateY(0) translateX(-80px);
    padding-left: 90px;
  }

  .choose__title {
    font-size: 25px;
    line-height: 31px;
  }

  .choose__top {
    padding-left: 0;
  }
  .choose__top span {
    font-size: 14px;
    line-height: 18px;
  }

  .choose__top::before {
    display: none;
  }

  .choose {
    padding: 20px 0 35px;
  }

  .choose-item {
    width: 450px;
  }

  .choose-item--team::before {
    height: 201px;
  }

  .choose-item {
    height: 200px;
  }

  .choose-item--team {
    padding-top: 51px;
  }

  .choose-item--orange::before {
    height: 201px;
    padding-left: 87px;
  }

  .choose-item--orange {
    padding-top: 50px;
  }

  .choose-item--top {
    margin-top: 0;
  }

  .choose-item--blue {
    padding-top: 50px;
  }
  .choose-item--blue::before {
    height: 194px;
  }

  .choose-item__descr {
    font-size: 14px;
    line-height: 15px;
  }

  .choose-item--blue .choose-item__icon {
    height: 40px;
    right: 40px;
    top: 30px;
  }
  .choose-item--blue .choose-item__icon img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
  }

  .choose-item--green .choose-item__icon {
    right: 106px;
    top: 15px;
  }

  .choose-item--top .choose-item__icon {
    width: 50px;
    height: 50px;
    right: 92px;
    top: 20px;
  }
  .choose-item--top .choose-item__icon img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
  }

  .choose-item--team:last-child {
    margin-top: 30px;
  }
  .choose-item--team:last-child .choose-item__icon {
    left: 231px;
  }

  .choose__wrapper--text {
    grid-template-columns: 1fr;
    margin-top: 35px;
    row-gap: 20px;
  }

  .choose-item-text {
    font-size: 14px;
    line-height: 18px;
  }

  .choose__btn {
    margin: 60px auto 0 auto;
    width: 360px;
    padding: 24px 26px;
  }

  .choose__bg {
    display: none;
  }
}
.reviews {
  padding: 100px 0;
  position: relative;
}

.reviews__subtitle {
  font-weight: 400;
  font-size: 26px;
  line-height: 33px;
  color: #ffffff;
}

.reviews__wrapper {
  margin-top: 120px;
  width: calc(100% + 40px);
  transform: translateX(-20px);
}
.reviews__wrapper .swiper {
  padding: 18px 18px 0;
}
.reviews__wrapper .swiper-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
}

.reviews-item {
  border-radius: 10px;
  background-color: #000;
  background-clip: padding-box;
  border: solid 3px transparent;
  border-right: 0;
  border-left: 0;
  padding: 24px 20px 10px;
  min-height: 316px;
  display: flex;
  flex-direction: column;
  position: relative;
}
.reviews-item:hover > p::before {
  opacity: 0;
}
.reviews-item:hover > p::after {
  opacity: 1;
}
.reviews-item::before {
  content: "";
  position: absolute;
  background-image: url("../img/reviews-item-bfr.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 243px;
  height: 295px;
  z-index: -1;
  left: -17px;
  bottom: -20px;
}
.reviews-item::after {
  content: "";
  position: absolute;
  background-image: url("../img/reviews-item-aft.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 243px;
  height: 295px;
  z-index: -1;
  right: -17px;
  top: -20px;
}
.reviews-item > span {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  margin: -3px;
  border-radius: inherit;
  background: linear-gradient(92.94deg, #ffea2b 12.91%, #51ffcb 71.79%);
  pointer-events: none;
}
.reviews-item > p {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  transition: all 0.3s ease-in-out;
  border-radius: 10px;
  overflow: hidden;
  pointer-events: none;
}
.reviews-item > p::before, .reviews-item > p::after {
  width: 100%;
  height: 100%;
  position: absolute;
  content: "";
  transition: all 0.3s ease-in-out;
}
.reviews-item > p::before {
  background: linear-gradient(108.65deg, #000000 1.1%, #2d2d2d 97.06%);
}
.reviews-item > p::after {
  background: linear-gradient(107.46deg, rgba(81, 255, 203, 0) -12.51%, rgba(45, 45, 45, 0.66) 97.01%);
  opacity: 0;
}

.reviews-item__top {
  display: flex;
  z-index: 1;
  position: relative;
}
.reviews-item__top img {
  width: 49px;
  min-width: 49px;
  height: 50px;
}
.reviews-item__top > div {
  margin-left: 22px;
}
.reviews-item__top p {
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  color: #ffffff;
}

.reviews-item__name {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  font-family: "Krona One";
  z-index: 1;
  position: relative;
}

.reviews-item__descr {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #ffffff;
  margin-top: 10px;
  margin-bottom: 20px;
  z-index: 1;
  position: relative;
}

.reviews-item__bottom {
  margin-top: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  z-index: 1;
  position: relative;
}

.reviews-item__date {
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  margin-right: 10px;
}

.reviews-item__arrow {
  background-image: url("../img/reviews-arrow.svg");
  width: 50px;
  height: 16px;
  display: block;
}

.reviews__bg {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: -1;
  object-fit: cover;
}

.nav-slider {
  display: flex;
  align-items: center;
  margin: 100px auto 0 auto;
  width: fit-content;
}
.nav-slider .swiper-button-next,
.nav-slider .swiper-button-prev {
  position: static;
  margin: 0;
  width: fit-content;
}
.nav-slider .swiper-button-next:hover::after,
.nav-slider .swiper-button-prev:hover::after {
  filter: brightness(20);
}
.nav-slider .swiper-button-next::after,
.nav-slider .swiper-button-prev::after {
  font-size: 0;
  transition: all 0.3s ease-in-out;
}
.nav-slider .swiper-button-next::after {
  content: url("../img/arrow-next.svg");
}
.nav-slider .swiper-button-prev::after {
  content: url("../img/arrow-prev.svg");
}
.nav-slider .swiper-pagination {
  position: static;
  margin: 0 20px;
}
.nav-slider .swiper-pagination-bullet {
  background: #5e5e5e;
  width: 15px;
  height: 15px;
  opacity: 1;
  margin: 0 14px !important;
  transform: scale(1);
}
.nav-slider .swiper-pagination-bullet-active {
  background: linear-gradient(141.2deg, #ffea2b 4.84%, #51ffcb 106.03%);
  transform: scale(1.4);
  transition: all 0.9s ease-in-out;
}

@media (max-width: 1200px) {
  .reviews__wrapper .swiper {
    padding: 18px 40px 0;
  }
}
@media (max-width: 992px) {
  .reviews__wrapper {
    margin-top: 60px;
  }
}
@media (max-width: 767px) {
  .reviews {
    padding: 35px 0 50px;
  }

  .reviews__subtitle {
    font-size: 14px;
    line-height: 18px;
  }

  .nav-slider {
    margin-top: 55px;
  }

  .nav-slider .swiper-button-next,
.nav-slider .swiper-button-prev {
    display: none;
  }

  .reviews__wrapper .swiper-pagination {
    padding-bottom: 4px;
  }

  .reviews__bg {
    display: none;
  }
}
.trust {
  padding: 110px 0 60px 0;
}
.trust .container--slider {
  max-width: 1360px;
}

.trust__subtitle {
  font-weight: 400;
  font-size: 26px;
  line-height: 33px;
  color: #ffffff;
}

.trust__wrapper {
  margin-top: 50px;
}
.trust__wrapper .swiper {
  padding: 0 5px;
  padding-top: 60px;
}
.trust__wrapper .nav-slider {
  margin-top: 60px;
}

.trust-item {
  border-radius: 30px;
  background: #000;
  background-clip: padding-box;
  border: solid 3px transparent;
  position: relative;
  padding: 20px;
}
.trust-item:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  margin: -3px;
  border-radius: inherit;
  pointer-events: none;
  background: linear-gradient(333.56deg, rgba(255, 234, 43, 0.304) 14.81%, rgba(72, 255, 43, 0.8) 107.56%);
}
.trust-item:hover .trust-item__img:after {
  opacity: 1;
}

.trust-item__img {
  width: 100%;
  height: 338px;
  margin: -60px auto 0 auto;
  background: #000;
  background-clip: padding-box;
  border: solid 2px transparent;
  position: relative;
  border-radius: 20px;
}
.trust-item__img:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  margin: -2px;
  border-radius: inherit;
  pointer-events: none;
  background: linear-gradient(157.03deg, #48ff2b 12.01%, #51ffcb 99.87%);
  border-radius: 20px;
  z-index: 1;
}
.trust-item__img::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(156.8deg, rgba(255, 234, 43, 0.35) 3.49%, rgba(81, 255, 203, 0.35) 92.21%);
  left: 0;
  top: 0;
  z-index: 2;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}
.trust-item__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
  z-index: 2;
  position: relative;
}

.trust-item__name {
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  margin-top: 24px;
  font-family: "Krona One";
}

.trust-item__position {
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  margin-top: 3px;
}

.trust-item__contact {
  margin-top: 15px;
  display: flex;
  justify-content: flex-end;
}
.trust-item__contact a {
  width: 25px;
  min-width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(135.74deg, rgba(255, 234, 43, 0.7) 0%, rgba(81, 255, 203, 0.7) 110.02%);
  border-radius: 50%;
  margin-right: 10px;
}
.trust-item__contact a:last-child {
  margin-right: 0;
}

.trust__bottom {
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-weight: 500;
  font-size: 25px;
  line-height: 32px;
  color: #ffffff;
}

.trust__btn {
  margin-top: 14px;
  width: 472px;
}

@media (max-width: 767px) {
  .trust__subtitle {
    font-size: 14px;
    line-height: 18px;
  }

  .trust__wrapper {
    margin-top: 53px;
  }

  .trust {
    padding: 50px 0;
  }

  .trust__bottom {
    font-size: 14px;
    line-height: 18px;
  }

  .trust__btn {
    width: 360px;
    padding: 24px 15px !important;
  }
}
.choice {
  padding: 60px 0;
  position: relative;
}

.choice__wrapper {
  margin-top: 90px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 55px;
}
.choice__wrapper .choose-item:nth-child(odd) {
  transform: translateY(129px);
}
.choice__wrapper .choose-item__descr {
  font-weight: 400;
  font-size: 26px;
  line-height: 33px;
  margin-top: 0;
  text-fill-color: transparent;
}
.choice__wrapper .choose-item {
  padding: 90px 35px 30px 60px;
}
.choice__wrapper .choose-item__icon {
  width: 113px;
  height: 113px;
}

.choice-item--red::before {
  background-image: url("../img/choice-item--red.svg");
  width: 100%;
  height: 243px;
  left: 0;
  top: 0;
}
.choice-item--red .choose-item__descr {
  background: linear-gradient(95.1deg, #f20000 2.67%, #ffb951 79.5%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow-y: hidden;
}
.choice-item--red .choose-item__icon {
  background: #000;
  background-clip: padding-box;
  border: solid 3px transparent;
  position: absolute;
  border-right: 0;
  border-left: 0;
  left: 92px;
  top: -50px;
}
.choice-item--red .choose-item__icon:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  margin: -3px;
  border-radius: inherit;
  background: linear-gradient(180deg, rgba(255, 43, 43, 0.4) 0%, rgba(255, 185, 81, 0.4) 100%);
  pointer-events: none;
}

.choice-item--green {
  padding-top: 70px !important;
}
.choice-item--green::before {
  background-image: url("../img/choice-item--green.svg");
  width: 100%;
  height: 243px;
  left: 0;
  top: 0;
}
.choice-item--green .choose-item__descr {
  background: linear-gradient(95.9deg, #48ff2b 8.17%, #ffea2b 92.48%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.choice-item--green .choose-item__icon {
  border: 3px solid rgba(255, 234, 43, 0.4);
  right: 92px;
  top: -52px;
}

.choice__line {
  position: absolute;
  bottom: -70%;
  width: 100%;
  pointer-events: none;
}

@media (max-width: 1200px) {
  .choice .choice__wrapper .choose-item:nth-child(odd) {
    transform: translateY(129px) translateX(-40px);
  }
  .choice .choice__wrapper {
    column-gap: 0;
  }
}
@media (max-width: 992px) {
  .choice .choice__title {
    font-size: 25px;
    line-height: 31px;
  }
  .choice .choice__wrapper {
    grid-template-columns: 1fr;
  }
  .choice .choice__wrapper .choose-item:nth-child(odd) {
    transform: translateY(0) translateX(0);
  }
  .choice .choose-item:nth-child(even) {
    transform: translateY(0) translateX(0);
  }
  .choice .choice-item--red::before {
    height: 248px;
  }
  .choice .choice-item--green::before {
    height: 248px;
  }
  .choice .choice__wrapper .choose-item {
    margin: 0 auto;
  }
  .choice .choice__wrapper .choose-item:nth-child(odd) {
    transform: translateX(40px);
    margin-left: auto !important;
  }
  .choice .choice__wrapper .choose-item:nth-child(odd)::before {
    background-position: left;
  }
  .choice .choice__wrapper .choose-item:nth-child(even) {
    transform: translateX(-40px);
  }
  .choice .choice__wrapper .choose-item:nth-child(even)::before {
    background-position: right;
  }
  .choice .choice__wrapper .choose-item {
    margin: 0;
  }
  .choice .choice-item--green {
    margin-top: 30px !important;
  }
  .choice .choice__wrapper .choose-item__descr {
    font-size: 18px;
    line-height: 23px;
  }
  .choice .choice-item--red::before,
.choice .choice-item--green::before {
    height: 203px;
  }
}
@media (max-width: 500px) {
  .choice .choose-item {
    width: 365px;
  }
  .choice .choice__wrapper .choose-item:nth-child(odd) {
    transform: translateX(20px) !important;
  }
  .choice .choice__wrapper .choose-item:nth-child(even) {
    transform: translateX(-20px) !important;
  }
}

@media (max-width: 767px) {
  .choice {
    padding: 50px 0 80px;
  }
}
.contact {
  padding: 190px 0 75px 0;
}

.contact__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.contact__left {
  max-width: 558px;
}

.contact__subtitle {
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  margin-top: 15px;
}

.contact__list {
  margin-top: 45px;
  padding-left: 24px;
  position: relative;
}
.contact__list::before {
  content: "";
  position: absolute;
  background-image: url("../img/about-line.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 12px;
  height: 116%;
  left: -3px;
  transform: translateY(-10px);
}
.contact__list li {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  margin-bottom: 25px;
}

.contact__right {
  max-width: 532px;
  padding: 65px 60px 55px;
  background: linear-gradient(339.33deg, rgba(255, 255, 255, 0.2) -3.33%, rgba(217, 217, 217, 0) 107%);
  border-radius: 30px;
  background: #212020;
  background-clip: padding-box;
  border: solid 4px transparent;
  position: relative;
  border-right: 0;
  border-left: 0;
}
.contact__right:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  margin: -4px;
  border-radius: inherit;
  background: linear-gradient(92.94deg, #ffea2b 12.91%, #51ffcb 71.79%);
  pointer-events: none;
}
.contact__right .form input {
  color: rgba(255, 255, 255, 0.8);
  border-bottom: 3px solid #ffffff;
}
.contact__right .form input::placeholder {
  color: rgba(255, 255, 255, 0.8);
}
.contact__right .form h3 {
  color: #ffffff;
}
.contact__right .form select {
  color: rgba(255, 255, 255, 0.8);
  border-bottom: 3px solid #ffffff;
}
.contact__right .form select::placeholder {
  color: rgba(255, 255, 255, 0.8);
}
.contact__right .form select option {
  color: #000;
}
.contact__right .form__bottom {
  color: rgba(255, 255, 255, 0.8);
}

.form input {
  width: 100%;
  border: none;
  background-color: transparent;
  padding-bottom: 15px;
  margin-bottom: 15px;
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
  border-radius: 0;
}
.form select {
  width: 100%;
  border: none;
  background-color: transparent;
  padding-bottom: 15px;
  margin-bottom: 15px;
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
  border-radius: 0;
  cursor: pointer;
}
.form .just-validate-error-label {
  margin-top: -10px;
  margin-bottom: 20px;
}

.form__title {
  font-weight: 400;
  font-size: 32px;
  line-height: 40px;
  color: #ffffff;
  font-family: "Krona One";
  margin-bottom: 30px;
}

.form__wrap h3 {
  font-family: "Krona One";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  margin-top: 40px;
  margin-bottom: 30px;
}

.form__wrap {
  margin-bottom: 50px;
}

.form__btn {
  width: 100%;
  height: 55px;
  font-size: 16px;
  line-height: 20px;
}
.form__btn span {
  font-family: "Krona One";
  text-transform: uppercase;
  white-space: nowrap;
}
.form__btn span::after {
  width: 24px !important;
  height: 24px !important;
}

.form__bottom {
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  margin-top: 10px;
}

@media (max-width: 1150px) {
  .contact__wrapper {
    flex-direction: column;
  }

  .contact__left {
    max-width: 100%;
  }

  .contact__right {
    max-width: 100%;
    margin-top: 30px;
    width: 100%;
  }
}
@media (max-width: 767px) {
  .contact__subtitle {
    font-size: 14px;
    line-height: 18px;
  }

  .contact__list {
    display: none;
  }

  .contact__right {
    padding: 65px 30px 50px;
  }

  .form__title {
    font-size: 25px;
    line-height: 31px;
  }

  .contact {
    padding: 80px 0 50px;
  }
  .contact .btn--telegram,
.contact .btn--whatsapp {
    font-size: 13px;
  }
}
.footer {
  position: relative;
  padding: 75px 0;
}

.footer__wrapper {
  display: flex;
  justify-content: space-between;
}

.footer-col {
  width: fit-content;
}

.footer-menu {
  margin-top: 50px;
}
.footer-menu li {
  margin-bottom: 20px;
}
.footer-menu a {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
}
.footer-menu a:hover {
  text-decoration: underline;
}

.footer__title {
  font-family: "Krona One";
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
}

.soc-wrap {
  display: flex;
  margin-top: 16px;
}
.soc-wrap li {
  margin-right: 23px;
  transition: all 0.3s ease-in-out;
}
.soc-wrap li:hover {
  opacity: 0.6;
}
.soc-wrap li:last-child {
  margin-right: 0;
}

.footer-send {
  margin-top: 30px;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
}
.footer-send::before {
  content: "";
  background-image: url("../img/mail.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 34px;
  height: 34px;
  margin-right: 25px;
}

.footer__line {
  position: absolute;
  z-index: -1;
  right: 0;
}

@media (max-width: 1060px) {
  .footer__wrapper {
    display: grid;
    grid-template-columns: repeat(3, auto);
    row-gap: 40px;
  }

  .footer-col:first-child {
    grid-column: 1/4;
  }

  .footer-menu {
    margin-top: 20px;
  }
}
@media (max-width: 767px) {
  .footer__wrapper {
    grid-template-columns: repeat(2, auto);
  }

  .footer-col:first-child {
    grid-column: 1/3;
  }
  .footer-col:nth-child(4) {
    grid-column: 1/3;
  }

  .footer {
    padding: 50px 0;
  }

  .footer-menu a {
    font-weight: 400;
  }

  .footer__line {
    display: none;
  }
}
.modal-wrap {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.69);
  box-shadow: 10px 20px 50px rgba(0, 0, 0, 0.24);
  z-index: 20000000;
  opacity: 0;
  visibility: hidden;
  overflow-y: auto;
  transition: opacity 0.3s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-wrap.active {
  opacity: 1;
  visibility: visible;
}

.modal {
  scrollbar-width: none;
  -ms-overflow-style: none;
  overflow-y: auto;
  height: fit-content;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-height: 95vh;
  min-width: 532px;
  max-width: 532px;
}
.modal::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.modal__body {
  background: linear-gradient(339.17deg, #48ff2b -5.77%, #51ffcb 104.59%);
  border-radius: 30px;
  padding: 80px 60px;
  width: 100%;
  position: relative;
}
.modal__body .form__title {
  color: #000000;
  font-size: 32px;
  line-height: 40px;
}
.modal__body .form input {
  color: rgba(0, 0, 0, 0.8);
  border-bottom: 3px solid #000000;
}
.modal__body .form input::placeholder {
  color: rgba(0, 0, 0, 0.8);
}
.modal__body .form h3 {
  color: #000000;
}
.modal__body .form select {
  color: rgba(0, 0, 0, 0.8);
  border-bottom: 3px solid #000000;
}
.modal__body .form select::placeholder {
  color: rgba(0, 0, 0, 0.8);
}
.modal__body .btn--telegram,
.modal__body .btn--whatsapp {
  background: linear-gradient(92.5deg, #000000 -4.72%, #000000 117.19%);
}

.modal__close {
  position: absolute;
  right: 20px;
  top: 20px;
  width: 36px;
  height: 36px;
  border-radius: 8px;
  cursor: pointer;
  transition: filter 0.2s ease-in-out;
}
.modal__close::before {
  content: "";
  position: absolute;
  background-image: url("../img/close.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 34px;
  height: 34px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  filter: brightness(0);
}

@media (max-width: 550px) {
  .modal {
    min-width: 90%;
    max-width: 90%;
  }

  .modal__body {
    padding: 40px 20px;
    padding-top: 60px;
  }

  .modal__body .btn--telegram,
.modal__body .btn--whatsapp {
    font-size: 14px;
  }

  .modal__body .form__title {
    font-size: 28px;
  }

  .form__wrap h3 {
    margin-top: 15px;
  }

  .form__wrap {
    margin-bottom: 30px;
  }
}
.burger {
  height: 16px;
  width: 40px;
  justify-content: center;
  align-items: center;
  z-index: 3500;
  cursor: pointer;
  transition: all 0.3s;
  display: none;
  position: relative;
}
.burger span {
  position: absolute;
  background: #fff;
  height: 6px;
  width: 40px;
  display: block;
  transition: all 0.3s;
  border-radius: 5px;
}
.burger span:nth-child(1) {
  top: 18px;
}
.burger span:nth-child(2) {
  top: 50%;
  transform: translateY(-50%);
}
.burger span:nth-child(3) {
  bottom: 18px;
}
.burger.active span:nth-child(1) {
  top: 5px;
  transform: rotate(45deg);
}
.burger.active span:nth-child(2) {
  opacity: 0;
}
.burger.active span:nth-child(3) {
  bottom: 5px;
  transform: rotate(-45deg);
}

.thank-sec {
  position: relative;
  padding: 250px 0 120px 0;
}

.thank-sec__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.thank-sec__title {
  font-family: "Krona One";
  font-weight: 400;
  font-size: 46px;
  line-height: 58px;
  text-align: center;
  background: radial-gradient(83.55% 1146.25% at 24.13% 44.74%, #ffea2b 0%, rgba(81, 255, 203, 0.94) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  text-shadow: 0px 0px 5px #96ffd9;
}

.thank-sec-top {
  margin-top: 50px;
  display: flex;
}
.thank-sec-top img {
  border-radius: 50%;
  width: 262px;
  height: 257px;
}
.thank-sec-top > div {
  margin-left: 40px;
  max-width: 472px;
}
.thank-sec-top > div div {
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #ffffff;
}
.thank-sec-top > div a {
  width: 170px;
  height: 50px;
  min-width: 170px;
  font-size: 18px;
  line-height: 23px;
  font-family: "Space Grotesk";
}
.thank-sec-top > div a span {
  flex-direction: row-reverse;
  font-family: "Space Grotesk";
}
.thank-sec-top > div a span::after {
  width: 24px;
  height: 24px;
  margin-left: 0;
  margin-right: 15px;
}

.thank-sec-bottom {
  margin-top: 95px;
}

.thank-sec-bottom__title {
  font-family: "Krona One";
  font-weight: 400;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  background: radial-gradient(58.93% 151.19% at 67.9% 50%, #ffea2b 0%, #ff9900 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  margin-bottom: 65px;
}

.thank-sec-next {
  max-width: 650px;
  display: flex;
  margin-bottom: 65px;
}
.thank-sec-next:last-child {
  margin-bottom: 0;
}
.thank-sec-next img {
  width: 120px;
  height: 120px;
}
.thank-sec-next div {
  margin-left: 60px;
}
.thank-sec-next div h3 {
  font-weight: 700;
  font-size: 24px;
  line-height: 31px;
  background: radial-gradient(100% 643.96% at 100% 41.94%, #89cdff 0%, #00a3ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  margin-bottom: 20px;
}
.thank-sec-next div p {
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #ffffff;
}
.thank-sec-next div a {
  margin-top: 24px;
  width: 240px;
  height: 50px;
  font-size: 18px;
  line-height: 23px;
  font-family: "Space Grotesk";
}
.thank-sec-next div .btn--twitter {
  background: #1cb7eb;
}

@media (max-width: 992px) {
  .thank-sec__title {
    font-size: 32px;
    line-height: 50px;
  }
}
@media (max-width: 767px) {
  .thank-sec-top {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .thank-sec-top > div {
    margin-top: 30px;
    margin-left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .thank-sec-next {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .thank-sec-next div {
    margin-left: 0;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .thank-sec-bottom {
    margin-top: 50px;
  }

  .thank-sec {
    padding: 160px 0 100px 0;
  }
}