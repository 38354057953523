* {
    font-family: "Space Grotesk", sans-serif;
}

body {
    background-color: #000;
    &.scroll-hide {
        overflow: hidden;
    }
}

.container {
    max-width: 1177px;
    margin: 0 auto;
    padding: 0 15px;
}

.big-container {
    max-width: 1600px;
}

.page {
    position: relative;
    overflow: hidden;
    min-height: 100vh;
    @media (max-width: 767px) {
        position: relative;
        &::before {
            content: "";
            position: absolute;
            background-image: url("../img/page-line-mob.svg");
            background-repeat: no-repeat;
            background-size: cover;
            width: 100%;
            height: 100%;
            left: 0;
            top: 300px;
        }
    }
}

.orange-text {
    background: radial-gradient(
        58.93% 151.19% at 67.9% 50%,
        #ffea2b 0%,
        #ff9900 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.green-text {
    background: radial-gradient(
        31.01% 282.69% at 9.96% 22.9%,
        #ffea2b 0%,
        #51ffcb 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.title {
    font-weight: 400;
    font-size: 52px;
    line-height: 65px;
    background: radial-gradient(
        61.41% 1041.08% at 17.44% 38.59%,
        #ffea2b 0%,
        #51ffcb 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    font-family: "Krona One";
}

@media (max-width: 767px) {
    .title {
        font-size: 30px;
        line-height: 38px;
    }
}
