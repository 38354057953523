.header {
    padding: 64px 0;
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 22222;
    .soc-wrap {
        display: none;
    }
}

.header__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.logo {
    z-index: 2222;
}
.nav__list {
    display: flex;
}
.nav__item {
    margin-right: 60px;
    &:last-child {
        margin-right: 0;
    }
}
.nav__link {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #ffffff;
    position: relative;
    &::before {
        position: absolute;
        content: "";
        width: 100%;
        height: 2px;
        bottom: -2px;
        left: 0;
        background: linear-gradient(
            267.47deg,
            #ffea2b -4.85%,
            #48ff2b 56.9%,
            #96ffd9 106.85%
        );
        display: none;
    }
    &:hover {
        background: linear-gradient(
            267.47deg,
            #ffea2b -4.85%,
            #48ff2b 56.9%,
            #96ffd9 106.85%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        &::before {
            display: block;
        }
    }
}

@media (max-width: 1460px) {
    .header {
        padding: 30px 0;
    }
}

@media (max-width: 1200px) {
    .nav__item {
        margin-right: 35px;
    }
    .header {
        padding: 50px 0;
    }
}

@media (max-width: 992px) {
    .nav {
        position: fixed;
        width: 100%;
        height: 100%;
        background: #000000;
        left: 0;
        top: 0;
        display: flex;
        flex-direction: column;
        padding: 150px 30px 30px;
        transform: scale(0.8);
        opacity: 0;
        pointer-events: none;
        transition: all 0.3s ease-in-out;
        z-index: 222;
        &.active {
            transform: scale(1);
            opacity: 1;
            pointer-events: all;
        }
    }

    .nav__list {
        flex-direction: column;
        li {
            margin-right: 0;
            margin-bottom: 20px;
            a {
                font-size: 16px;
                line-height: 20px;
            }
        }
    }

    .header .soc-wrap {
        display: flex;
        margin-top: auto;
        justify-content: center;
    }

    .burger {
        display: block !important;
    }

    .lang {
        display: none;
    }
}
