.project {
    padding: 167px 0 100px;
    position: relative;
    .container {
        max-width: 1177px;
    }
}

.project__title {
    font-family: "Krona One";
    font-weight: 400;
    font-size: 42px;
    line-height: 52px;
    color: #ffffff;
    span {
        font-family: "Krona One";
    }
}

.project-tabs {
    margin-top: 55px;
    display: flex;
    flex-wrap: wrap;
    max-width: 640px;
    position: relative;
    z-index: 1;
}
.project-tabs-item {
    padding: 20px 30px;
    border-radius: 56px;
    $border: 4px;
    background-clip: padding-box;
    border: solid $border transparent;
    position: relative;
    background-color: #000;
    width: fit-content;
    min-width: 167px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-right: 15px;
    margin-bottom: 34px;
    &:before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        margin: -$border;
        border-radius: inherit;
        background: linear-gradient(92.94deg, #ffea2b 12.91%, #51ffcb 71.79%);
        pointer-events: none;
    }
    span {
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        background: radial-gradient(
            88.33% 6494.5% at 7.53% 0%,
            #ffea2b 0%,
            #51ffcb 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        pointer-events: none;
    }
    &.active {
        background: linear-gradient(102.33deg, #48ff2b 9.01%, #51ffcb 96.42%);
        span {
            color: #000000;
            background: none;
            -webkit-text-fill-color: inherit;
        }
    }
}
.project-content {
    &.hide {
        display: none;
    }
}
.project-content__list {
    padding-left: 10px;
    li {
        font-weight: 500;
        font-size: 18px;
        line-height: 23px;
        color: #ffffff;
        margin-bottom: 17px;
        padding-left: 42px;
        position: relative;
        &::before {
            content: "";
            position: absolute;
            background-image: url("../img/check-mark.png");
            background-repeat: no-repeat;
            background-size: cover;
            width: 20px;
            height: 20px;
            left: 0;
            top: 1px;
        }
    }
}
.project-content__descr {
    font-weight: 300;
    font-size: 18px;
    line-height: 30px;
    color: #ffffff;
    margin-top: 37px;
    max-width: 487px;
}
.project-content__btn {
    margin-top: 41px;
    width: 474px;
}

.project-content__img {
    position: absolute;
    height: 100%;
    right: 0;
    object-fit: cover;
    top: 0;
    z-index: -1;
    pointer-events: none;
}

@media (max-width: 767px) {
    .project {
        padding: 80px 0 45px 0;
    }

    .project__title {
        font-size: 22px;
        line-height: 28px;
    }

    .project-tabs {
        margin-top: 20px;
    }

    .project-tabs-item {
        margin-bottom: 15px;
    }

    .project-content__list li {
        font-size: 12px;
        line-height: 15px;
        padding-left: 34px;
        margin-bottom: 13px;
    }

    .project-content__list li::before {
        top: 0px;
    }
    .project-content__list {
        margin-top: 15px;
    }

    .project-content__descr {
        font-size: 14px;
        line-height: 18px;
        margin-top: 15px;
    }

    .project-content__btn {
        margin: 30px auto 0 auto;
        max-width: 365px;
        width: 100%;
        padding: 24px 21px !important;
        font-size: 14px;
        line-height: 18px;
    }

    .project-content__img {
        transform: translateX(40%);
        opacity: 0.8;
    }

    .project-tabs-item {
        padding: 10px;
        &:first-child {
            grid-column: 1 / 3;
            width: 100%;
        }
        span {
            font-size: 12px;
            line-height: 13px;
        }
    }

    .project-tabs {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
}
