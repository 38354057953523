.footer {
    position: relative;
    padding: 75px 0;
}
.container {
}
.footer__wrapper {
    display: flex;
    justify-content: space-between;
}
.footer-col {
    width: fit-content;
}

.footer-menu {
    margin-top: 50px;
    li {
        margin-bottom: 20px;
    }
    a {
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        color: #ffffff;
        &:hover {
            text-decoration: underline;
        }
    }
}

.footer__logo {
}
.footer__title {
    font-family: "Krona One";
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #ffffff;
}
.soc-wrap {
    display: flex;
    margin-top: 16px;
    li {
        margin-right: 23px;
        transition: all 0.3s ease-in-out;
        &:hover {
            opacity: 0.6;
        }
        &:last-child {
            margin-right: 0;
        }
    }
}
.footer-send {
    margin-top: 30px;
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #ffffff;
    &::before {
        content: "";
        background-image: url("../img/mail.svg");
        background-repeat: no-repeat;
        background-size: cover;
        width: 34px;
        height: 34px;
        margin-right: 25px;
    }
}
.footer__line {
    position: absolute;
    z-index: -1;
    right: 0;
}

@media (max-width: 1060px) {
    .footer__wrapper {
        display: grid;
        grid-template-columns: repeat(3, auto);
        row-gap: 40px;
    }

    .footer-col {
        &:first-child {
            grid-column: 1 / 4;
        }
    }

    .footer-menu {
        margin-top: 20px;
    }
}

@media (max-width: 767px) {
    .footer__wrapper {
        grid-template-columns: repeat(2, auto);
    }
    .footer-col {
        &:first-child {
            grid-column: 1 / 3;
        }
        &:nth-child(4) {
            grid-column: 1 / 3;
        }
    }

    .footer {
        padding: 50px 0;
    }

    .footer-menu a {
        font-weight: 400;
    }

    .footer__line {
        display: none;
    }
}
