.burger {
    height: 16px;
    width: 40px;
    justify-content: center;
    align-items: center;
    z-index: 3500;
    cursor: pointer;
    transition: all 0.3s;
    display: none;
    position: relative;

    span {
        position: absolute;
        background: #fff;
        height: 6px;
        width: 40px;
        display: block;
        transition: all 0.3s;
        border-radius: 5px;

        &:nth-child(1) {
            top: 18px;
        }

        &:nth-child(2) {
            top: 50%;
            transform: translateY(-50%);
        }

        &:nth-child(3) {
            bottom: 18px;
        }
    }

    &.active {
        span {
            &:nth-child(1) {
                top: 5px;
                transform: rotate(45deg);
            }

            &:nth-child(2) {
                opacity: 0;
            }

            &:nth-child(3) {
                bottom: 5px;
                transform: rotate(-45deg);
            }
        }
    }
}
