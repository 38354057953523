.choose {
    padding: 90px 0;
    .container {
        position: relative;
    }
}

.choose__top {
    font-weight: 400;
    font-size: 21px;
    line-height: 27px;
    width: 560px;
    margin-left: auto;
    padding-left: 20px;
    position: relative;
    margin-top: 19px;
    &::before {
        content: "";
        position: absolute;
        background-image: url("../img/arrow-choose.svg");
        background-repeat: no-repeat;
        background-size: cover;
        width: 16px;
        height: 63px;
        left: -3px;
        top: 11px;
    }
    span {
        padding-left: 5px;
    }
}
.choose__title {
    font-size: 42px;
    line-height: 52px;
    margin-bottom: 5px;
}

.choose__wrapper {
    margin-top: 80px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 40px;
    row-gap: 130px;
    .gallery {
        a {
            font-weight: 700;
            font-size: 18px;
            line-height: 23px;
            text-decoration-line: underline;
            color: #2ad2ff;
            transition: all 0.3s ease-in-out;
            display: none;
            &:first-child {
                display: block;
            }
            &:hover {
                letter-spacing: 1px;
            }
            img {
                display: none;
            }
        }
    }
}

.choose__wrapper--text {
    margin-top: 130px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 40px;
    row-gap: 50px;
}

.choose-item {
    padding: 55px 35px;
    position: relative;
    height: fit-content;
    &::before {
        pointer-events: none;
        background-repeat: no-repeat;
        background-size: cover;
        content: "";
        position: absolute;
    }
    &:nth-child(odd) {
        transform: translateY(-129px);
    }
}
.choose-item__icon {
    position: absolute;
    background-color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    pointer-events: none;
}
.choose-item__title {
    font-weight: 700;
    font-size: 26px;
    line-height: 33px;
    background: linear-gradient(94.11deg, #ffea2b -1.1%, #51ffcb 19.66%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    span {
        font-size: 32px;
        line-height: 41px;
    }
}
.choose-item__descr {
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    color: #ffffff;
    margin-top: 16px;
}
.choose-item--orange {
    &::before {
        background-image: url("../img/choose-item--orange.svg");
        width: 100%;
        height: 246px;
        left: 0;
        top: 0;
    }
    .choose-item__icon {
        width: 113px;
        height: 113px;
        right: 55px;
        top: -62px;
    }
}
.choose-item--green {
    background: radial-gradient(
        67.7% 124.15% at 21.48% 10.11%,
        rgba(81, 255, 203, 0.91) 0%,
        rgba(72, 255, 43, 0.91) 100%
    );
    .choose-item__icon {
        background-color: transparent;
        right: 10px;
        top: 10px;
    }
    .choose-item__title {
        color: #000000;
        background: none;
        -webkit-text-fill-color: inherit;
    }
    .choose-item__descr {
        color: #000000;
    }
}
.choose-item--blue {
    &::before {
        background-image: url("../img/choose-item--blue.svg");
        width: 100%;
        height: 238px;
        left: 0;
        top: 0;
    }
    .choose-item__icon {
        right: 20px;
        top: 20px;
    }
}
.choose-item--top {
    .choose-item__icon {
        background-color: transparent;
        right: 15px;
        top: 20px;
    }
}
.choose-item--team {
    &::before {
        background-image: url("../img/choose-item--team.svg");
        width: 100%;
        height: 246px;
        left: 0;
        top: 0;
    }
    .choose-item__icon {
        border: 3px solid rgba(255, 234, 43, 0.4);
        width: 113px;
        height: 113px;
        left: 111px;
        top: -62px;
    }
}
.choose-item-text {
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
    color: #ffffff;
    height: fit-content;
    span {
        font-weight: 700;
    }
    &:nth-child(odd) {
        transform: translateY(-129px);
    }
}
.gradi-text {
    background: linear-gradient(93.5deg, #ffea2b -0.2%, #51ffcb 40%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}
.choose__btn {
    width: 464px;
}

.choose__bg {
    position: absolute;
    bottom: 110px;
    left: 50%;
    transform: translateX(-50%);
    pointer-events: none;
}

@media (max-width: 1200px) {
    .choose-item:nth-child(odd) {
        transform: translateY(0) translateX(-40px);
        &::before {
            background-position: right;
        }
    }
    .choose-item:nth-child(even) {
        transform: translateX(40px);
        &::before {
            background-position: left;
        }
    }

    .choose__top {
        width: 100%;
    }

    .choose__wrapper {
        row-gap: 60px;
        column-gap: 0;
    }

    .choose-item-text:nth-child(odd) {
        transform: translateY(0);
    }

    .choose__btn {
        margin-top: 65px;
    }
}

@media (max-width: 992px) {
    .choose__wrapper {
        grid-template-columns: 1fr;
        row-gap: 30px;
    }
    .choose-item {
        width: 557px;
        padding: 20px;
        height: 249px;
    }

    .choose-item:nth-child(odd) {
        transform: translateY(0) translateX(40px);
        margin-left: auto;
        padding-right: 60px;
    }
    .choose-item:nth-child(even) {
        transform: translateY(0) translateX(-40px);
        margin-right: auto;
        padding-left: 60px;
    }
    .choose-item--orange .choose-item__icon {
        width: 85px;
        height: 85px;
        top: -41px;
        img {
            max-width: 100%;
            max-height: 100%;
            object-fit: cover;
        }
    }

    .choose-item--team .choose-item__icon {
        width: 85px;
        height: 85px;
        top: -41px;
        padding: 15px;
        img {
            max-width: 100%;
            max-height: 100%;
            object-fit: cover;
        }
    }

    .choose-item--blue::before {
        height: 240px;
    }
    .choose-item--team::before {
        height: 248px;
    }

    .choose-item--green .choose-item__icon {
        right: 74px;
        top: 19px;
        width: 40px;
        height: 40px;
        img {
            max-width: 100%;
            max-height: 100%;
            object-fit: cover;
        }
    }

    .choose-item--team {
        padding-top: 65px;
        padding-left: 51px;
    }

    .choose-item__title {
        font-size: 14px;
        line-height: 18px;
        span {
            font-size: 25px;
            line-height: 32px;
        }
    }

    .choose-item--orange {
        margin-top: 30px;
    }

    .choose-item--blue {
        padding-top: 50px;
    }

    .choose-item--top {
        padding: 50px 30px;
    }
}

@media (max-width: 767px) {
    .choose-item:nth-child(odd) {
        transform: translateY(0) translateX(20px);
        padding-right: 90px;
    }
    .choose-item:nth-child(even) {
        transform: translateY(0) translateX(-80px);
        padding-left: 90px;
    }
    .choose__title {
        font-size: 25px;
        line-height: 31px;
    }

    .choose__top {
        padding-left: 0;
        span {
            font-size: 14px;
            line-height: 18px;
        }
    }

    .choose__top::before {
        display: none;
    }
    .choose {
        padding: 20px 0 35px;
    }

    .choose-item {
        width: 450px;
    }

    .choose-item--team::before {
        height: 201px;
    }

    .choose-item {
        height: 200px;
    }
    .choose-item--team {
        padding-top: 51px;
    }

    .choose-item--orange::before {
        height: 201px;
        padding-left: 87px;
    }
    .choose-item--orange {
        padding-top: 50px;
    }

    .choose-item--top {
        margin-top: 0;
    }

    .choose-item--blue {
        padding-top: 50px;
        &::before {
            height: 194px;
        }
    }

    .choose-item__descr {
        font-size: 14px;
        line-height: 15px;
    }

    .choose-item--blue .choose-item__icon {
        height: 40px;
        right: 40px;
        top: 30px;
        img {
            max-width: 100%;
            max-height: 100%;
            object-fit: cover;
        }
    }

    .choose-item--green .choose-item__icon {
        right: 106px;
        top: 15px;
    }

    .choose-item--top .choose-item__icon {
        width: 50px;
        height: 50px;
        right: 92px;
        top: 20px;
        img {
            max-width: 100%;
            max-height: 100%;
            object-fit: cover;
        }
    }

    .choose-item--team {
        &:last-child {
            margin-top: 30px;
            .choose-item__icon {
                left: 231px;
            }
        }
    }

    .choose__wrapper--text {
        grid-template-columns: 1fr;
        margin-top: 35px;
        row-gap: 20px;
    }

    .choose-item-text {
        font-size: 14px;
        line-height: 18px;
    }

    .choose__btn {
        margin: 60px auto 0 auto;
        width: 360px;
        padding: 24px 26px;
    }

    .choose__bg {
        display: none;
    }
}
