.services {
    padding: 59px 0 85px;
    position: relative;
    .nav-slider {
        display: none;
    }
}
.services__bg {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: -1;
    object-fit: cover;
}
.container {
}
.services__title {
    font-size: 42px;
    line-height: 52px;
}
.title {
}
.services__descr {
    font-weight: 300;
    font-size: 26px;
    line-height: 33px;
    color: #ffffff;
    max-width: 808px;
    margin-top: 10px;
}
.orange-text {
}
.services__wrapper {
    margin-top: 78px;
}

.services__wrapper {
    @media (min-width: 767px) {
        .swiper-wrapper {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            column-gap: 35px;
            row-gap: 50px;
        }
        .swiper {
            overflow: visible;
        }
    }
    @media (min-width: 767px) and (max-width: 1200px) {
        .swiper-wrapper {
            grid-template-columns: repeat(3, 1fr);
        }
        .swiper-slide {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    @media (min-width: 767px) and (max-width: 920px) {
        .swiper-wrapper {
            grid-template-columns: repeat(2, 1fr);
        }
    }
}

.services-item {
    position: relative;
    width: 260px;
    height: 342px;
    padding: 15px 15px 25px 15px;
    display: flex;
    flex-direction: column;
    &::before {
        content: "";
        position: absolute;
        background-image: url("../img/services-border.svg");
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        pointer-events: none;
    }
    &::after {
        position: absolute;
        content: "";
        background: radial-gradient(
            113.69% 212.07% at 12.12% 7.31%,
            rgba(255, 234, 43, 0.23) 0%,
            rgba(81, 255, 203, 0.23) 100%
        );
        border-radius: 30px;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        opacity: 0;
        transition: all 0.3s ease-in-out;
        pointer-events: none;
    }
    &:hover {
        &::after {
            opacity: 1;
        }
    }
}
.services-item__top {
    display: flex;
    align-items: center;
    div {
        width: 58px;
        min-width: 58px;
        height: 58px;
        border-radius: 20px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        &::before {
            content: "";
            position: absolute;
            background-image: url("../img/services-item-border.svg");
            background-repeat: no-repeat;
            background-size: cover;
            width: 100%;
            height: 100%;
            pointer-events: none;
            left: 0;
            top: 0;
        }
    }
    p {
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        margin-left: 8px;
        background: radial-gradient(
            148.08% 1844.93% at -8.45% -48.08%,
            #ffea2b 0%,
            #51ffcb 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
    }
}
.services-item__desrc {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #ffffff;
    margin-top: 23px;
    margin-bottom: 20px;
}
.services-item__bottom {
    margin-top: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.services-item__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 130px;
    height: 32px;
    border-radius: 56px;
    font-weight: 400;
    font-size: 8px;
    line-height: 10px;
    overflow: hidden;
    position: relative;
    transition: all 0.3s ease-in-out;
    &:after {
        background: rgb(255, 255, 255);
        content: "";
        height: 110px;
        left: -75px;
        opacity: 0.7;
        position: absolute;
        top: -50px;
        transform: rotate(35deg);
        transition: all 0.8s cubic-bezier(0.19, 1, 0.22, 1);
        width: 26px;
        z-index: 22;
    }
    &:hover {
        filter: hue-rotate(45deg);
        &:after {
            left: 120%;
            transition: all 0.8s cubic-bezier(0.19, 1, 0.22, 1);
        }
    }
    span {
        font-family: "Krona One";
        z-index: 22;
    }
}
.services-item__btn--gradi {
    background: linear-gradient(93.25deg, #ffea2b -2.32%, #51ffcb 127.24%);
    color: #000000;
    margin-bottom: 14px;
}
.services-item__btn--border {
    $border: 1px;
    background: #000;
    background-clip: padding-box;
    border: solid $border transparent;
    background: linear-gradient(92.63deg, #ffea2b 11.65%, #48ff2b 84.85%);
    &:before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        margin: -$border;
        border-radius: inherit;
        background-color: #000;
        z-index: 21;
    }
    span {
        background: linear-gradient(92.63deg, #ffea2b 11.65%, #48ff2b 84.85%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
    }
}

@media (max-width: 767px) {
    .services {
        .swiper-grid-column > .swiper-wrapper {
            flex-direction: unset;
            row-gap: 54px;
        }
        .swiper-slide {
            margin-top: 0 !important;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .nav-slider {
            margin: 40px auto 0 auto;
            display: flex;
        }
    }

    .services__title {
        font-size: 25px;
        line-height: 31px;
    }

    .services__descr {
        font-size: 14px;
        line-height: 18px;
        margin-top: 2px;
    }

    .services__wrapper {
        margin-top: 45px;
    }

    .services {
        padding: 45px 0 20px;
    }

    .services__bg {
        display: none;
    }
}
