.choice {
    padding: 60px 0;
    position: relative;
}
.container {
}
.choice__title {
}
.title {
}
.choice__wrapper {
    margin-top: 90px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 55px;
    .choose-item:nth-child(odd) {
        transform: translateY(129px);
    }
    .choose-item__descr {
        font-weight: 400;
        font-size: 26px;
        line-height: 33px;
        margin-top: 0;
        text-fill-color: transparent;
    }
    .choose-item {
        padding: 90px 35px 30px 60px;
    }
    .choose-item__icon {
        width: 113px;
        height: 113px;
    }
}

.choice-item--red {
    &::before {
        background-image: url("../img/choice-item--red.svg");
        width: 100%;
        height: 243px;
        left: 0;
        top: 0;
    }
    .choose-item__descr {
        background: linear-gradient(95.1deg, #f20000 2.67%, #ffb951 79.5%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        @include text-hide(4);
    }
    .choose-item__icon {
        $border: 3px;
        background: #000;
        background-clip: padding-box;
        border: solid $border transparent;
        position: absolute;
        border-right: 0;
        border-left: 0;
        left: 92px;
        top: -50px;
        &:before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: -1;
            margin: -$border;
            border-radius: inherit;
            background: linear-gradient(
                180deg,
                rgba(255, 43, 43, 0.4) 0%,
                rgba(255, 185, 81, 0.4) 100%
            );
            pointer-events: none;
        }
    }
}
.choose-item__icon {
}
.choose-item__descr {
}
.choice-item--green {
    padding-top: 70px !important;
    &::before {
        background-image: url("../img/choice-item--green.svg");
        width: 100%;
        height: 243px;
        left: 0;
        top: 0;
    }
    .choose-item__descr {
        background: linear-gradient(95.9deg, #48ff2b 8.17%, #ffea2b 92.48%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
    }
    .choose-item__icon {
        border: 3px solid rgba(255, 234, 43, 0.4);
        right: 92px;
        top: -52px;
    }
}
.choice__line {
    position: absolute;
    bottom: -70%;
    width: 100%;
    pointer-events: none;
}

.choice {
    @media (max-width: 1200px) {
        .choice__wrapper .choose-item:nth-child(odd) {
            transform: translateY(129px) translateX(-40px);
        }

        .choice__wrapper {
            column-gap: 0;
        }
    }

    @media (max-width: 992px) {
        .choice__title {
            font-size: 25px;
            line-height: 31px;
        }

        .choice__wrapper {
            grid-template-columns: 1fr;
        }

        .choice__wrapper .choose-item:nth-child(odd) {
            transform: translateY(0) translateX(0);
        }
        .choose-item:nth-child(even) {
            transform: translateY(0) translateX(0);
        }

        .choice-item--red::before {
            height: 248px;
        }

        .choice-item--green::before {
            height: 248px;
        }

        .choice__wrapper .choose-item {
            margin: 0 auto;
        }

        .choice__wrapper .choose-item:nth-child(odd) {
            transform: translateX(40px);
            margin-left: auto !important;
            &::before {
                background-position: left;
            }
        }
        .choice__wrapper .choose-item:nth-child(even) {
            transform: translateX(-40px);
            &::before {
                background-position: right;
            }
        }

        .choice__wrapper .choose-item {
            margin: 0;
        }

        .choice-item--green {
            margin-top: 30px !important;
        }

        .choice__wrapper .choose-item__descr {
            font-size: 18px;
            line-height: 23px;
        }

        .choice-item--red::before,
        .choice-item--green::before {
            height: 203px;
        }
    }

    @media (max-width: 500px) {
        .choose-item {
            width: 365px;
        }
        .choice__wrapper .choose-item:nth-child(odd) {
            transform: translateX(20px) !important;
        }
        .choice__wrapper .choose-item:nth-child(even) {
            transform: translateX(-20px) !important;
        }
    }
}

@media (max-width: 767px) {
    .choice {
        padding: 50px 0 80px;
    }
}
