.marketing {
    position: relative;
    padding: 100px 0;
    $border: 4px;
    background-clip: padding-box;
    border: solid $border transparent;
    border-right: 0;
    border-left: 0;
    &:before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        margin: -$border;
        border-radius: inherit;
        background: linear-gradient(92.94deg, #ffea2b 12.91%, #51ffcb 71.79%);
        pointer-events: none;
    }

    .container {
        max-width: 1176px;
    }
}
.marketing__bg {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: -1;
    object-fit: cover;
}

.marketing__title {
    font-family: "Krona One";
    font-weight: 400;
    font-size: 49px;
    line-height: 61px;
    background: linear-gradient(92.88deg, #ffea2b 9.78%, #51ffcb 83.35%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    z-index: 2;
    position: relative;
}
.marketing__descr {
    font-weight: 500;
    font-size: 36px;
    line-height: 46px;
    color: #ffffff;
    text-shadow: -3px 4px 3px rgba(0, 0, 0, 0.25);
    max-width: 861px;
    margin-top: 23px;
    z-index: 2;
    position: relative;
    .green {
        font-weight: 700;
        font-size: 40px;
        line-height: 51px;
        color: #48ff2b;
    }
}

.marketing__bottom {
    margin-top: 60px;
    font-weight: 500;
    font-size: 21px;
    line-height: 27px;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: fit-content;
    position: relative;
    z-index: 2;
    position: relative;
    &::before {
        content: "";
        position: absolute;
        background-image: url("../img/marketing-arrow-btn.svg");
        background-repeat: no-repeat;
        background-size: cover;
        width: 71px;
        height: 83px;
        right: -119px;
        top: 0;
    }
}
.marketing__btn {
    padding: 23px 21px;
}

.marketing__tel {
    position: absolute;
    right: 119px;
    top: -90px;
}
.marketing__check {
    position: absolute;
}
.marketing__check1 {
    left: 19px;
    bottom: 96px;
}
.marketing__check2 {
    right: -25px;
    bottom: 7px;
}
.marketing__check3 {
    right: 53px;
    top: 46px;
}

@media (max-width: 1400px) {
    .marketing__check1 {
        bottom: inherit;
        top: 0;
        left: -89px;
        top: -116px;
    }

    .marketing__tel {
        right: -209px;
    }

    .marketing__check3 {
        right: 111px;
        top: 306px;
    }

    .marketing__check2 {
        right: -116px;
        bottom: -110px;
    }
}

@media (max-width: 992px) {
    .marketing__title {
        max-width: 397px;
    }

    .marketing__descr {
        max-width: 597px;
    }
}

@media (max-width: 767px) {
    .marketing__title {
        font-size: 30px;
        line-height: 38px;
        max-width: 282px;
    }
    .marketing__descr {
        font-size: 16px;
        line-height: 20px;
        max-width: 308px;
        .green {
            font-size: 16px;
            line-height: 20px;
        }
    }

    .marketing__tel {
        height: 469px;
        right: -156px;
    }

    .marketing__check3 {
        right: 53px;
        top: 188px;
    }

    .marketing__check2 {
        height: 230px;
        right: -80px;
        bottom: -99px;
    }

    .marketing__check1 {
        height: 230px;
    }

    .marketing__bottom {
        margin: 60px auto 0 auto;
    }
    .marketing__btn {
        height: 64px;
    }

    .marketing__bottom::before {
        right: -23px;
        top: -52px;
    }

    .marketing {
        padding: 140px 0 100px 0;
        margin-top: 100px;
    }
}
