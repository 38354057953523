.thank-sec {
    position: relative;
    padding: 250px 0 120px 0;
}
.container {
}
.thank-sec__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.thank-sec__title {
    font-family: "Krona One";
    font-weight: 400;
    font-size: 46px;
    line-height: 58px;
    text-align: center;
    background: radial-gradient(
        83.55% 1146.25% at 24.13% 44.74%,
        #ffea2b 0%,
        rgba(81, 255, 203, 0.94) 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    text-shadow: 0px 0px 5px #96ffd9;
}
.thank-sec-top {
    margin-top: 50px;
    display: flex;
    img {
        border-radius: 50%;
        width: 262px;
        height: 257px;
    }
    > div {
        margin-left: 40px;
        max-width: 472px;
        div {
            font-weight: 500;
            font-size: 18px;
            line-height: 23px;
            color: #ffffff;
        }

        a {
            width: 170px;
            height: 50px;
            min-width: 170px;
            font-size: 18px;
            line-height: 23px;
            font-family: "Space Grotesk";
            span {
                flex-direction: row-reverse;
                font-family: "Space Grotesk";
                &::after {
                    width: 24px;
                    height: 24px;
                    margin-left: 0;
                    margin-right: 15px;
                }
            }
        }
    }
}
.thank-sec-top__btn {
}
.btn {
}
.btn--telegram {
}
.thank-sec-bottom {
    margin-top: 95px;
}
.thank-sec-bottom__title {
    font-family: "Krona One";
    font-weight: 400;
    font-size: 32px;
    line-height: 40px;
    text-align: center;
    background: radial-gradient(
        58.93% 151.19% at 67.9% 50%,
        #ffea2b 0%,
        #ff9900 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    margin-bottom: 65px;
}
.thank-sec-next {
    max-width: 650px;
    display: flex;
    margin-bottom: 65px;
    &:last-child {
        margin-bottom: 0;
    }
    img {
        width: 120px;
        height: 120px;
    }
    div {
        margin-left: 60px;
        h3 {
            font-weight: 700;
            font-size: 24px;
            line-height: 31px;
            background: radial-gradient(
                100% 643.96% at 100% 41.94%,
                #89cdff 0%,
                #00a3ff 100%
            );
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
            margin-bottom: 20px;
        }
        p {
            font-weight: 500;
            font-size: 18px;
            line-height: 23px;
            color: #ffffff;
        }
        a {
            margin-top: 24px;
            width: 240px;
            height: 50px;
            font-size: 18px;
            line-height: 23px;
            font-family: "Space Grotesk";
        }
        .btn--twitter {
            background: #1cb7eb;
        }
    }
}

@media (max-width: 992px) {
    .thank-sec__title {
        font-size: 32px;
        line-height: 50px;
    }
}

@media (max-width: 767px) {
    .thank-sec-top {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .thank-sec-top > div {
        margin-top: 30px;
        margin-left: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .thank-sec-next {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .thank-sec-next div {
        margin-left: 0;
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .thank-sec-bottom {
        margin-top: 50px;
    }

    .thank-sec {
        padding: 160px 0 100px 0;
    }
}
