.cases {
    padding: 133px 0;
    .container {
        max-width: 1240px;
    }
}

.cases__title {
}
.title {
}
.cases__subtitle {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #ffffff;
    max-width: 401px;
}
.cases__wrapper {
    margin-top: 40px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 52px;
    column-gap: 30px;
}
.cases-item {
    display: flex;
    height: fit-content;
    > div {
        display: flex;
        flex-direction: column;
    }
    &:hover {
        .cases-item__img {
            &::before {
                opacity: 1;
            }
        }
        .cases-item__btn {
            background: radial-gradient(
                125% 4666.67% at 95.24% 125%,
                #ffea2b 0%,
                #51ffcb 100%
            );
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
            &::after {
                background-image: url("../img/btn-arrow-gradi.svg");
            }
        }
    }
}
.cases-item__img {
    width: 285px;
    min-width: 285px;
    height: 282px;
    margin-right: 34px;
    position: relative;
    &::before {
        content: "";
        position: absolute;
        background: linear-gradient(
            114.81deg,
            rgba(255, 234, 43, 0.77) 8.17%,
            rgba(81, 255, 203, 0.77) 105.29%
        );
        border-radius: 10px;
        width: 100%;
        height: 100%;
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
    }
    img {
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
    }
}
.cases-item__logo {
    height: fit-content;
}
.cases-item__title {
    font-family: "Krona One";
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    background: radial-gradient(
        134.21% 2801.18% at 8.42% -34.21%,
        #ffea2b 0%,
        #51ffcb 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    margin-top: 16px;
}
.cases-item__descr {
    margin-top: 15px;
    margin-bottom: 20px;
    h3 {
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        color: #ffffff;
        font-family: "Krona One";
        padding-bottom: 2px;
    }
    p {
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #ffffff;
        margin-bottom: 6px;
        span {
            font-weight: 400;
            font-size: 18px;
            line-height: 22px;
            color: #ffffff;
            font-family: "Krona One";
            padding-bottom: 2px;
        }
    }
}
.cases-item__btn {
    font-family: "Krona One";
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #ffffff;
    margin-top: auto;
    display: flex;
    align-items: center;
    transition: all 0.3s ease-in-out;
    &::after {
        content: "";
        background-image: url("../img/btn-arrow.svg");
        background-repeat: no-repeat;
        background-size: cover;
        width: 57px;
        height: 15px;
        margin-left: 11px;
    }
}

.cases__bottom {
    margin-top: 64px;
    display: flex;
    flex-direction: column;
    align-items: center;
    p {
        font-weight: 400;
        font-size: 25px;
        line-height: 32px;
        text-align: center;
        color: #ffffff;
        max-width: 565px;
    }
}
.cases__btn {
    margin-top: 12px;
    width: 350px;
}

@media (max-width: 1460px) {
    .cases-item__img {
        width: 200px;
        min-width: 200px;
        height: 200px;
    }

    .cases-item__logo {
        height: 40px;
        img {
            max-width: 100%;
            max-height: 100%;
            object-fit: cover;
        }
    }

    .cases-item__title {
        font-size: 12px;
        line-height: 15px;
        margin-top: 8px;
    }

    .cases-item__descr {
        margin: 10px 0;
    }

    .cases-item__descr h3 {
        font-size: 12px;
        line-height: 16px;
    }

    .cases-item__descr p {
        font-size: 12px;
        line-height: 16px;
    }

    .cases-item__descr p span {
        font-size: 12px;
        line-height: 14px;
    }

    .cases-item__btn {
        font-size: 14px;
        line-height: 14px;
    }
    .cases__wrapper {
        margin-top: 30px;
        row-gap: 30px;
    }

    .cases__bottom p {
        font-size: 18px;
        line-height: 24px;
    }

    .cases__btn {
        padding: 15px 33px;
    }
}

@media (max-width: 1200px) {
    .cases-item {
        flex-direction: column;
    }

    .cases-item__img {
        margin-right: 0;
        margin-bottom: 16px;
    }
}

@media (max-width: 767px) {
    .cases__wrapper {
        grid-template-columns: 1fr;
        width: 285px;
        margin: 40px auto 0 auto;
    }

    .cases__subtitle {
        font-size: 14px;
        line-height: 18px;
    }

    .cases__bottom p {
        font-size: 14px;
        line-height: 18px;
    }

    .cases {
        padding: 60px 0 90px;
    }

    .cases-item {
        position: relative;
        padding-top: 70px;
    }

    .cases-item__logo {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
    }

    .cases-item__title {
        margin-top: 0;
    }
}
