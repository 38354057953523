.contact {
    padding: 190px 0 75px 0;
}

.contact__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.contact__left {
    max-width: 558px;
}

.contact__subtitle {
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    color: #ffffff;
    margin-top: 15px;
}
.contact__list {
    margin-top: 45px;
    padding-left: 24px;
    position: relative;
    &::before {
        content: "";
        position: absolute;
        background-image: url("../img/about-line.svg");
        background-repeat: no-repeat;
        background-size: cover;
        width: 12px;
        height: 116%;
        left: -3px;
        transform: translateY(-10px);
    }
    li {
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        color: #ffffff;
        margin-bottom: 25px;
    }
}
.contact__right {
    max-width: 532px;
    padding: 65px 60px 55px;
    background: linear-gradient(
        339.33deg,
        rgba(255, 255, 255, 0.2) -3.33%,
        rgba(217, 217, 217, 0) 107%
    );
    border-radius: 30px;
    $border: 4px;
    background: rgb(33, 32, 32);
    background-clip: padding-box;
    border: solid $border transparent;
    position: relative;
    border-right: 0;
    border-left: 0;
    &:before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        margin: -$border;
        border-radius: inherit;
        background: linear-gradient(92.94deg, #ffea2b 12.91%, #51ffcb 71.79%);
        pointer-events: none;
    }
    .form {
        input {
            color: rgba(255, 255, 255, 0.8);
            &::placeholder {
                color: rgba(255, 255, 255, 0.8);
            }
            border-bottom: 3px solid #ffffff;
        }
        h3 {
            color: #ffffff;
        }
        select {
            color: rgba(255, 255, 255, 0.8);

            &::placeholder {
                color: rgba(255, 255, 255, 0.8);
            }
            option {
                color: #000;
            }
            border-bottom: 3px solid #ffffff;
        }
    }
    .form__bottom {
        color: rgba(255, 255, 255, 0.8);
    }
}
.form {
    input {
        width: 100%;
        border: none;
        background-color: transparent;
        padding-bottom: 15px;
        margin-bottom: 15px;
        font-weight: 300;
        font-size: 14px;
        line-height: 18px;
        border-radius: 0;
    }

    select {
        width: 100%;
        border: none;
        background-color: transparent;
        padding-bottom: 15px;
        margin-bottom: 15px;
        font-weight: 300;
        font-size: 14px;
        line-height: 18px;
        border-radius: 0;
        cursor: pointer;
    }

    .just-validate-error-label {
        margin-top: -10px;
        margin-bottom: 20px;
    }
}
.form__title {
    font-weight: 400;
    font-size: 32px;
    line-height: 40px;
    color: #ffffff;
    font-family: "Krona One";
    margin-bottom: 30px;
}
.form__wrap {
    h3 {
        font-family: "Krona One";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        margin-top: 40px;
        margin-bottom: 30px;
    }
}
.form__wrap {
    margin-bottom: 50px;
}
.form__btn {
    width: 100%;
    height: 55px;
    font-size: 16px;
    line-height: 20px;
    span {
        font-family: "Krona One";
        text-transform: uppercase;
        white-space: nowrap;
        &::after {
            width: 24px !important;
            height: 24px !important;
        }
    }
}

.form__bottom {
    font-weight: 300;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    margin-top: 10px;
}

@media (max-width: 1150px) {
    .contact__wrapper {
        flex-direction: column;
    }

    .contact__left {
        max-width: 100%;
    }

    .contact__right {
        max-width: 100%;
        margin-top: 30px;
        width: 100%;
    }
}

@media (max-width: 767px) {
    .contact__subtitle {
        font-size: 14px;
        line-height: 18px;
    }

    .contact__list {
        display: none;
    }

    .contact__right {
        padding: 65px 30px 50px;
    }

    .form__title {
        font-size: 25px;
        line-height: 31px;
    }

    .contact {
        padding: 80px 0 50px;
        .btn--telegram,
        .btn--whatsapp {
            font-size: 13px;
        }
    }
}
