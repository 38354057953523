.trust {
    padding: 110px 0 60px 0;
    .container--slider {
        max-width: 1360px;
    }
}

.trust__subtitle {
    font-weight: 400;
    font-size: 26px;
    line-height: 33px;
    color: #ffffff;
}
.trust__wrapper {
    margin-top: 50px;
    .swiper {
        padding: 0 5px;
        padding-top: 60px;
    }

    .nav-slider {
        margin-top: 60px;
    }
}

.trust-item {
    border-radius: 30px;
    $border: 3px;
    background: #000;
    background-clip: padding-box;
    border: solid $border transparent;
    position: relative;
    padding: 20px;
    &:before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        margin: -$border;
        border-radius: inherit;
        pointer-events: none;
        background: linear-gradient(
            333.56deg,
            rgba(255, 234, 43, 0.304) 14.81%,
            rgba(72, 255, 43, 0.8) 107.56%
        );
    }
    &:hover {
        .trust-item__img {
            &:after {
                opacity: 1;
            }
        }
    }
}
.trust-item__img {
    width: 100%;
    height: 338px;
    margin: -60px auto 0 auto;
    $border: 2px;
    background: #000;
    background-clip: padding-box;
    border: solid $border transparent;
    position: relative;
    border-radius: 20px;
    &:before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        margin: -$border;
        border-radius: inherit;
        pointer-events: none;
        background: linear-gradient(157.03deg, #48ff2b 12.01%, #51ffcb 99.87%);
        border-radius: 20px;
        z-index: 1;
    }
    &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background: linear-gradient(
            156.8deg,
            rgba(255, 234, 43, 0.35) 3.49%,
            rgba(81, 255, 203, 0.35) 92.21%
        );
        left: 0;
        top: 0;
        z-index: 2;
        opacity: 0;
        transition: all 0.3s ease-in-out;
    }
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 20px;
        z-index: 2;
        position: relative;
    }
}
.trust-item__name {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #ffffff;
    margin-top: 24px;
    font-family: "Krona One";
}
.trust-item__position {
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    color: #ffffff;
    margin-top: 3px;
}
.trust-item__contact {
    margin-top: 15px;
    display: flex;
    justify-content: flex-end;
    a {
        width: 25px;
        min-width: 25px;
        height: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: linear-gradient(
            135.74deg,
            rgba(255, 234, 43, 0.7) 0%,
            rgba(81, 255, 203, 0.7) 110.02%
        );
        border-radius: 50%;
        margin-right: 10px;
        &:last-child {
            margin-right: 0;
        }
    }
}

.trust__bottom {
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-weight: 500;
    font-size: 25px;
    line-height: 32px;
    color: #ffffff;
}
.trust__btn {
    margin-top: 14px;
    width: 472px;
}

@media (max-width: 767px) {
    .trust__subtitle {
        font-size: 14px;
        line-height: 18px;
    }
    .trust__wrapper {
        margin-top: 53px;
    }

    .trust {
        padding: 50px 0;
    }

    .trust__bottom {
        font-size: 14px;
        line-height: 18px;
    }

    .trust__btn {
        width: 360px;
        padding: 24px 15px !important;
    }
}
