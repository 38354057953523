.awards {
    @media (min-width: 767px) {
        min-height: 296px;
        .container {
            margin-right: 70px;
            padding: 0;
            max-width: 100%;
            &:first-child {
                margin-right: 0;
            }
        }
        .awards-item {
            margin-right: 70px;
        }
    }
    background-color: #000;
    padding: 50px 0;
    $border: 4px;
    background: #000;
    background-clip: padding-box;
    border: solid $border transparent;
    position: relative;
    border-right: 0;
    border-left: 0;
    &:before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        margin: -$border;
        border-radius: inherit;
        background: linear-gradient(92.94deg, #ffea2b 12.91%, #51ffcb 71.79%);
        pointer-events: none;
    }

    .swiper-wrapper {
        align-items: center;
        -webkit-transition-timing-function: linear !important;
        transition-timing-function: linear !important;
        justify-content: space-between;
    }
    .swiper-slide {
        width: auto;
    }
    .awards-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        div {
            height: 110px;
            display: flex;
            align-items: center;
        }
        p {
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            text-align: center;
            color: #ffffff;
            width: 100px;
            margin-top: 25px;
        }
    }
    .swiper-button-next,
    .swiper-button-prev {
        display: none;
    }
    .swiper-pagination {
        display: none;
    }
}

.awards__title {
    text-align: center;
    background: linear-gradient(95.53deg, #ffea2b 3.89%, #51ffcb 87.23%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    font-weight: 400;
    font-size: 32px;
    line-height: 40px;
    font-family: "Krona One";
}

.awards__wrapper {
    margin-top: 35px;
}

@media (max-width: 767px) {
    .awards {
        .swiper-grid-column > .swiper-wrapper {
            flex-direction: unset;
            row-gap: 30px;
        }

        .awards-item p {
            display: none;
        }

        .swiper {
            padding-bottom: 80px;
        }

        .swiper-pagination-bullet {
            background: #5e5e5e;
            width: 12px;
            height: 12px;
            opacity: 1;
            margin: 0 3px !important;
            transform: scale(1);
        }
        .swiper-pagination-bullet-active {
            background: linear-gradient(
                141.2deg,
                #ffea2b 4.84%,
                #51ffcb 106.03%
            );
            transition: all 0.9s ease-in-out;
        }
    }

    .awards .awards-item {
        margin-top: 0 !important;
    }

    .awards .swiper-pagination {
        display: flex;
        justify-content: center;
    }

    .awards__title {
        font-size: 25px;
        line-height: 31px;
    }

    .awards {
        padding: 17px 0 35px 0;
    }
}
