.about {
    padding: 100px 0 60px;
    .container {
        position: relative;
    }
}

.about__wrapper {
    max-width: 927px;
    margin-left: auto;
}

.about__img {
    position: absolute;
    top: -181px;
    left: -239px;
    z-index: -1;
}

.about__title {
    font-weight: 400;
    font-size: 32px;
    line-height: 40px;
    color: #ffffff;
    font-family: "Krona One";
    span {
        font-family: "Krona One";
    }
}

.about__subtitle {
    font-weight: 700;
    font-size: 26px;
    line-height: 33px;
    color: #ffffff;
    margin-top: 20px;
    max-width: 700px;
}
.about__list {
    margin-top: 20px;
    padding-left: 20px;
    position: relative;
    &::before {
        content: "";
        position: absolute;
        background-image: url("../img/about-line.svg");
        background-repeat: no-repeat;
        background-size: cover;
        width: 12px;
        height: 100%;
        left: -3px;
    }
    li {
        font-weight: 700;
        font-size: 18px;
        line-height: 23px;
        color: #ffffff;
        margin-bottom: 15px;
        &:last-child {
            margin-bottom: 0;
        }
    }
}
.about__descr {
    font-weight: 300;
    font-size: 18px;
    line-height: 23px;
    color: #ffffff;
    margin-top: 30px;
    max-width: 780px;
}
.about__btn {
    margin-top: 47px;
    width: 350px;
}

@media (max-width: 767px) {
    .about__title {
        font-size: 25px;
        line-height: 31px;
    }
    .about__subtitle {
        font-size: 18px;
        line-height: 24px;
    }
    .about__list li {
        font-size: 12px;
        line-height: 15px;
    }

    .about__descr {
        font-size: 14px;
        line-height: 18px;
        margin-top: 15px;
    }

    .about__btn {
        margin: 45px auto 0;
    }

    .about__img {
        display: none;
    }

    .about {
        padding: 60px 0 30px 0;
    }

    .about__list {
        &::before {
            background-position: bottom;
        }
    }
}
