.promo {
    padding-top: 258px;
    position: relative;
    padding-bottom: 170px;
}

.promo__bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: -3;
    pointer-events: none;
}

.promo__line {
    position: absolute;
    left: 0;
    bottom: 25px;
    width: 100%;
    z-index: -2;
    pointer-events: none;
}

.promo__wrapper {
    position: relative;
}
.promo-ofer {
    max-width: 680px;
}
.promo__title {
    font-weight: 400;
    font-size: 46px;
    line-height: 58px;
    font-family: "Krona One";
    background: radial-gradient(
        83.55% 1146.25% at 24.13% 44.74%,
        #ffea2b 0%,
        rgba(81, 255, 203, 0.94) 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    text-shadow: 0px 0px 5px rgba(81, 255, 203, 0.94);
}

.promo__subtitle {
    font-weight: 400;
    font-size: 28px;
    line-height: 35px;
    color: #ffffff;
    font-family: "Krona One";
    span {
        font-family: "Krona One";
    }
}

.promo__descr {
    margin-top: 25px;
    max-width: 610px;
    div {
        font-weight: 700;
        font-size: 26px;
        line-height: 33px;
        color: #ffffff;
        position: relative;
        &::before {
            content: "";
            position: absolute;
            background-image: url("../img/line-promo-descr.svg");
            background-repeat: no-repeat;
            background-size: cover;
            width: 179px;
            height: 7px;
            bottom: -5px;
        }
    }
    p {
        font-weight: 400;
        font-size: 18px;
        line-height: 23px;
        color: #ffffff;
        margin-top: 10px;
    }
}
.promo-advant {
    margin-top: 35px;
    display: flex;
}

.promo-advant-item--first {
    border-radius: 41px;
    padding: 30px 25px;
    font-weight: 700;
    font-size: 34px;
    line-height: 43px;
    color: #000000;
    background: radial-gradient(
        67.7% 124.15% at 21.48% 10.11%,
        rgba(81, 255, 203, 0.91) 0%,
        rgba(72, 255, 43, 0.91) 100%
    );
    p {
        font-weight: 500;
        font-size: 18px;
        line-height: 23px;
        color: #000000;
    }
}

.promo-advant-item {
    margin-left: 50px;
    width: fit-content;
    position: relative;
    border-radius: 41px;
    padding: 30px 25px;
    $border: 3px;
    background: #000;
    background-clip: padding-box;
    border: solid $border transparent;
    position: relative;
    &:after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        margin: -$border;
        border-radius: inherit;
        background: linear-gradient(
            121.42deg,
            #48ff2b -0.29%,
            rgba(255, 255, 255, 0) 63.34%
        );
        pointer-events: none;
    }
    div {
        font-weight: 700;
        font-size: 30px;
        line-height: 38px;
        background: radial-gradient(
            130% 720.72% at 24.03% 6%,
            #ffea2b 0%,
            #51ffcb 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        text-shadow: 0px 0px 4px #96ffd9;
    }
    p {
        font-weight: 500;
        font-size: 18px;
        line-height: 23px;
        color: #ffffff;
        margin-top: 5px;
    }
    &::before {
        position: absolute;
        border-radius: 41px;
        content: "";
        width: 100%;
        height: 100%;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        text-shadow: 0px 0px 4px #96ffd9;
        background: radial-gradient(
            97.75% 179.73% at 26.01% 24.32%,
            rgba(255, 255, 255, 0.12) 0%,
            rgba(255, 255, 255, 0) 100%
        );
        left: 0;
        top: 0;
        pointer-events: none;
    }
}
.promo-btn-wrap {
    margin-top: 48px;
    display: grid;
    grid-template-columns: repeat(2, 2fr);
    gap: 35px;
}

.promo-img {
    position: absolute;
    top: -190px;
    right: -73px;
    z-index: -1;
    pointer-events: none;
}

@media (max-width: 1460px) {
    .promo {
        padding-top: 155px;
        padding-bottom: 85px;
    }
    .promo__title {
        font-size: 32px;
        line-height: 39px;
        max-width: 500px;
    }

    .promo__subtitle {
        font-size: 20px;
        line-height: 28px;
        max-width: 500px;
    }

    .promo-ofer {
        max-width: 661px;
    }

    .promo__descr p {
        font-size: 14px;
        line-height: 19px;
    }

    .promo__descr div {
        font-size: 18px;
        line-height: 25px;
    }

    .promo-advant-item {
        padding: 20px 25px;
    }

    .promo-advant-item--first {
        padding: 20px 25px;
        font-size: 26px;
        line-height: 34px;
    }

    .promo-advant-item--first p {
        font-size: 16px;
        line-height: 21px;
    }

    .promo-advant-item p {
        font-size: 16px;
        line-height: 21px;
    }

    .promo-advant-item div {
        font-size: 26px;
        line-height: 34px;
    }

    .promo-advant {
        margin-top: 25px;
    }

    .promo-btn-wrap {
        margin-top: 35px;
    }

    .promo-advant-item {
        margin-left: 25px;
    }

    .promo-img {
        width: 727px;
        top: -145px;
        img {
            max-width: 100%;
            max-height: 100%;
            object-fit: cover;
        }
    }
}

@media (max-width: 1200px) {
    .promo-img {
        right: -472px;
        top: -253px;
        z-index: -1;
    }

    .promo__bg {
        display: none;
    }
}

@media (max-width: 992px) {
    .promo__title {
        font-size: 25px;
        line-height: 31px;
    }
    .promo__subtitle {
        font-size: 16px;
        line-height: 20px;
        margin-top: 10px;
    }

    .promo__descr div {
        font-size: 14px;
        line-height: 18px;
        &::before {
            background-position: right;
            width: 94px;
        }
    }

    .promo__descr {
        margin-top: 16px;
    }

    .promo__descr p {
        font-size: 12px;
        line-height: 15px;
    }
    .promo-advant-item--first {
        padding: 15px 10px;
    }
    .promo-advant-item--first p {
        font-size: 14px;
        line-height: 18px;
    }

    .promo-advant-item--first {
        font-size: 26px;
        line-height: 33px;
    }

    .promo-advant-item {
        padding: 15px 10px;
    }

    .promo-advant-item p {
        font-size: 16px;
        line-height: 20px;
    }
    .promo-advant-item div {
        font-size: 26px;
        line-height: 33px;
    }
}

@media (max-width: 767px) {
    .promo-btn-wrap {
        grid-template-columns: 1fr;
        gap: 20px;
        margin-top: 35px;
    }
    .promo__btn {
        width: 304px;
        margin: 0 auto;
    }
    .promo-advant {
    }
    .promo-advant-item {
        margin-left: 11px;
    }
    .promo__line {
        left: -492px;
        bottom: 179px;
        transform: rotate(351deg);
        width: 211%;
    }
}

@media (max-width: 420px) {
    .promo__title {
        font-size: 21px;
        line-height: 29px;
    }

    .header {
        padding: 20px 0;
    }

    .promo__subtitle {
        font-size: 14px;
        line-height: 18px;
    }

    .promo-advant-item--first p {
        font-size: 11px;
        line-height: 14px;
    }

    .promo-advant-item--first {
        font-size: 20px;
        line-height: 28px;
        border-radius: 28px;
    }

    .promo-advant-item p {
        font-size: 11px;
        line-height: 15px;
    }

    .promo-advant-item div {
        font-size: 23px;
        line-height: 28px;
    }

    .promo-advant-item {
        border-radius: 28px;
    }

    .promo {
        padding-top: 130px;
        .promo__btn {
            padding: 15px 33px;
            font-size: 14px;
            line-height: 18px;
        }
    }

    .promo-btn-wrap {
        margin-top: 25px;
        gap: 15px;
    }

    .logo {
        width: 150px;
        img {
            max-width: 100%;
            max-height: 100%;
            object-fit: cover;
        }
    }

    .promo-img {
        right: -342px;
        top: -156px;
    }
}
